import React, { useState } from "react";
import { Steps, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import "./Steps.scss";

const { Step } = Steps;

interface AntStepsProps {
	title?: string;
	description?: string;
	subTitle?: string;
}

const AntSteps: React.FC<AntStepsProps> = ({ title, description, subTitle }) => {
	const { t } = useTranslation();

	const [current] = useState<number>(1);

	const steps = [
		{ title: t("Уплачено"), description: t("18 февраля Уплата НДФЛ "), statusColor: "#5B7ED7" },
		{
			title: t("Сдано"),
			description: t("23 февраля Земельный налог"),
			statusColor: "#8CC152"
		},
		{
			title: t("Отчетность"),
			description: t("01 марта НДФЛ"),
			statusColor: "#5B7ED7"
		},
		{
			title: t("Просрочено"),
			description: t("31 марта ИНПС "),
			statusColor: "#E9573F"
		}
	];

	const status = [
		{
			title: t("Нет просроченых налогов и отчетностей"),
			date: t("Сегодня 18 февраля"),
			obligation: t("5 Ближайших обязательств"),
			statusColor: "#8CC152"
		}
	];

	return (
		<div className="steps">
			{status.map((stat) => (
				<>
					<Row justify={"space-between"}>
						<Col className="steps__status">
							<div className="square__item" style={{ backgroundColor: stat.statusColor }}></div> {stat.title}
						</Col>
						<Col className="steps__date">{stat.date}</Col>
						<Col className="steps__obligation">{stat.obligation}</Col>
					</Row>
				</>
			))}

			<Row>
				<Col span={24}>
					<Steps progressDot current={current}>
						{steps.map((step) => (
							<Step title={step.title} description={step.description} />
						))}
					</Steps>
				</Col>
			</Row>
		</div>
	);
};

export default AntSteps;
