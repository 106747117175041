import React, { FC } from 'react'
import { Drawer } from 'antd'
import close from "assets/images/icons/close.svg";

type Props = {
    onClose: () => void
    visible: boolean;
    title?: string;
}

const DrawerMenu: FC<Props> = ({ onClose, title, children, visible, ...props }) => {

    return (
        <Drawer
            title={title}
            placement="right"
            onClose={onClose}
            visible={visible}
            closeIcon={<CloseIcon />}
            width={320}
            {...props}
            headerStyle={{ border: 'none', padding: 38, paddingBottom: 23, color: 'red' }}
            bodyStyle={{ padding: 38, paddingTop: 0 }}
        >
            {children}
        </Drawer>
    )
}

export default DrawerMenu


const CloseIcon = () => {
    return (
        <div style={{ position: 'absolute', top: 6, right: 6 }} >
            <img src={close} alt="" />
        </div>
    )
}