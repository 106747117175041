import ErrorReport from 'components/shared/Layout/ErrorsReport';
import { FC, useEffect } from 'react';

import Footer from './Footer';
import Header from './Header';
import classes from './Layout.module.scss';

export type Props = {
	children: any;
};

const Layout: FC<Props> = ({ children }) => {
	useEffect(() => {
		window.onbeforeunload = function () {
			window.scrollTo(0, 0);
		};

		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	return (
		<>
			<Header />

			<div className={classes.layout}>{children}</div>

			<Footer />
		</>
	);
};

export default Layout;
