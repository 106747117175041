import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { request, requestWithToken } from 'services/api';
import { packageDTO } from 'types/utils';

type QueryData = packageDTO;
type Props = {
	mxik: string;
	comapanyTin?: any;
	lang?: string;
};
const usePackageAPIQuery = ({ mxik, lang, comapanyTin = '' }: Props) => {
	const { i18n } = useTranslation();
	const query = useQuery(
		[`/mxik/get/by-mxik`, mxik, comapanyTin],
		async () => {
			const response = await request.get<QueryData>(`/mxik/get/by-mxik`, {
				params: { mxikCode: mxik, comapanyTin: comapanyTin || null, lang: i18n.language },
			});

			return response;
		},
		{ enabled: !!mxik }
	);

	return query;
};

export const usePackageAPIQuery2 = ({ mxik, comapanyTin = '' }: Props) => {
	const { i18n } = useTranslation();
	const query = useQuery(
		[`/mxik/get/by-mxik`, mxik, comapanyTin, i18n.language],
		async () => {
			const response = await requestWithToken.get<QueryData>(`/integration-nomenclature/get/by-mxik`, {
				params: { mxikCode: mxik, comapanyTin: comapanyTin || null, lang: i18n.language },
			});

			return response;
		},
		{ enabled: !!mxik }
	);

	return query;
};

export default usePackageAPIQuery;
