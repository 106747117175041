import { useGeneralAPIQuery } from "./queries";
import { categoryDTO, ExemptionDTO, unitsDTO } from "../types/utils";

export const useGetStepsQuery = (categoryId: number, enabled?: boolean) => {
	return useGeneralAPIQuery<categoryDTO>("/category/list", { category_id: categoryId }, { enabled: enabled });
};

export const useGetAllUnitsQuery = (pageNo?: number, pageSize?: number) => {
	return useGeneralAPIQuery<unitsDTO>("/integration-mxik/references/get/units/all", { pageNo, pageSize });
};

export const useGetExemptionQuery = (enabled?: boolean) => {
	return useGeneralAPIQuery<ExemptionDTO[]>("/integration-mxik/references/lgota", {}, { enabled: enabled });
};
