import classes from './ImageCard.module.scss';
import { Link } from 'react-router-dom';
import SvgEye from 'icons/SvgEye';

type Props = {
	imgSrc: string;
	title: string;
	badge?: string;
	date?: string;
	time?: string;
	link: string;
	views?: number;
};

const NewsCard = ({ title, link, badge, date, time, imgSrc, views }: Props) => {
	return (
		<div>
			<div style={{ background: badge ? '#e8f0fd' : '#ffffff' }} className={classes.card}>
				<div className={classes.cardHeader}>
					<img className={classes.img} alt='' src={imgSrc} />
					<div className={badge ? classes.red : classes.grey}></div>
				</div>
				<div className={classes.cardBody}>
					<Link className={classes.link} to={link}>
						{title}
					</Link>
				</div>
				<div className={classes.cardFooter.concat(' flex items-center justify-between')}>
					<p className={classes.date}>{date}</p>

					<p className='mb-0 flex items-center'>
						<SvgEye className='h-4' /> {views}
					</p>
				</div>
			</div>
		</div>
	);
};

export default NewsCard;
