import { Col, message } from 'antd';
import Button from 'components/Button';
import Captcha from 'components/Captcha';
import Input from 'components/shared/Input';
import get from 'lodash.get';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { requestWithToken } from 'services/api';

interface IProps {
	onSuccess: (...params: unknown[]) => unknown;
	text: string | null;
}

function ErrorReportText({ onSuccess, text }: IProps) {
	const { t } = useTranslation();

	const currUrl = window.location.href;

	const [store, setStore] = useState<{
		fixText: string | null;
		mxikCode: string | null;
		errorText: string | null;
		additional_info: string | null;
		captchaGuid: string | null;
		captcha: string | null;
	}>({
		fixText: null,
		errorText: null,
		mxikCode: null,
		additional_info: null,
		captchaGuid: null,
		captcha: null,
	});

	useEffect(() => {
		setStore((prev) => ({ ...prev, errorText: text }));
	}, [text]);

	const mutationTextFix = useMutation({
		mutationFn: () => {
			return requestWithToken({
				url: `/web/error/save`,
				method: 'POST',
				params: {
					guid: store.captchaGuid,
					captcha: store.captcha,
				},
				data: {
					errorTopic: `${currUrl} - ${store.errorText}`,
					mxik: store.mxikCode,
					suggestTopic: `${store.fixText} (${store.additional_info})`,
					status: 1,
				},
			});
		},
	});

	const onSubmit = async () => {
		try {
			await mutationTextFix.mutateAsync();
			message.success(t('Спасибо за помощь'));

			onSuccess();
			setStore({
				fixText: null,
				errorText: null,
				mxikCode: null,
				additional_info: null,
				captchaGuid: null,
				captcha: null,
			});
		} catch (error) {
			const errorMessage = get(error, 'response.data.error', null);

			if (errorMessage === `captcha parameter is missing`) {
				return message.error(t('Символы в рисунках не правильно'));
			}

			message.error(t('Хатолик юз берди'));
		}
	};

	return (
		<>
			<div className='!w-full !space-y-8'>
				<>
					<Input className='!bg-white' label={t('Выделенная ошибка')} value={store.errorText || (text ? text : '')} />

					{/* {isSelecctedTextValid ? null : <span className='text-rose-500'>{t('Выделите ошибочный текст')}</span>} */}
				</>

				<Input
					name='fix'
					label={t('Предлагаемый текст')}
					value={store.fixText || ''}
					onChange={(e) => setStore({ ...store, fixText: e.currentTarget.value })}
				/>

				<Input
					name='additional_info'
					label={t('Дополнительная информация (телефон, ИНН и название организации)')}
					value={store.additional_info || ''}
					onChange={(e) => setStore({ ...store, additional_info: e.currentTarget.value })}
				/>
			</div>

			<div className='w-full'>
				<div className='flex items-center w-1/2 space-x-5'>
					<Captcha onCaptchaChange={({ captchaGuid }) => setStore({ ...store, captchaGuid })} />
					<div className='w-full relative'>
						<Input
							status={!store.captcha ? 'error' : ''}
							placeholder={t('Напишите символ из рисунка')}
							className='w-full mb-0 !bg-white'
							value={store.captcha || ''}
							onChange={(e) => setStore({ ...store, captcha: e.currentTarget.value })}
						/>
					</div>
				</div>
			</div>

			<div className='flex items-center justify-center space-x-10'>
				<Button
					type='link'
					size='large'
					className='!py-4 !rounded-full !text-sm no-underline text-blue-default hover:text-blue-default !border-none !bg-transparent !flex !items-center !justify-center !mb-0'
				>
					{t('Отменить')}
				</Button>

				<Button
					loading={mutationTextFix.isLoading}
					onClick={onSubmit}
					disabled={!store.captcha || mutationTextFix.isLoading}
					type='primary'
					size='large'
					className='!py-4 cursor-pointer !px-10 !rounded-full !text-sm !border !border-gray-400 !flex !items-center !justify-center !mb-0'
				>
					{t('Отправить')}
				</Button>
			</div>
		</>
	);
}

export default ErrorReportText;
