export function timeConverterDay(UNIX_timestamp: any) {
	var a = new Date(UNIX_timestamp * 1000);
	var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var time = date + " " + month + " " + year;
	return time;
}

export function timeConverterHours(UNIX_timestamp: any) {
	let d = new Date(UNIX_timestamp);
	function addZero(i: any) {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	let h = addZero(d.getHours());
	let m = addZero(d.getMinutes());
	return h + ":" + m;
}

export function formatDate(date: any) {
	let d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();
	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}

export function formatTimeDate(updatedAt: number) {
	var today = new Date(updatedAt);
	var dd = String(today.getDate()).padStart(2, "0");
	var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
	var yyyy = today.getFullYear();

	return dd + "." + mm + "." + yyyy;
	// return new Date(updatedAt).toLocaleString("uz", {
	// 	day: "numeric",
	// 	month: "long",
	// 	year: "numeric"
	// });
}


export function formatTimeDate2(updatedAt: string) {
	// 24.09.2021 18:54:02
	const [date] = updatedAt.split(" ");

	return date;
	// return new Date(updatedAt).toLocaleString("uz", {
	// 	day: "numeric",
	// 	month: "long",
	// 	year: "numeric"
	// });
}
