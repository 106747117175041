import React, { FC } from "react";
import { Dropdown, DropDownProps } from "antd";
import ArrowDown from "../../assets/svg/arrowdown.svg";
import classes from "./Dropdown.module.scss";

type DropdownsProps = DropDownProps & {
	selected: any;
};

const DropdownMaker: FC<DropdownsProps> = ({ selected, ...props }) => {
	return (
		<Dropdown {...props}>
			<span className={classes.linkStyle} onClick={(e) => e.preventDefault()}>
				{selected} {<img src={ArrowDown} alt="arrow" />}
			</span>
		</Dropdown>
	);
};

export default DropdownMaker;
