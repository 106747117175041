import { useQuery } from "react-query";

import { request } from "services/api";
import { AllMxikInfoDTO, ResponseDTO } from "types/utils";
import i18n from "../../../services/i18n";

const useGetAllMxiksInfoQuery = () => {
	const query = useQuery(
		"/card/all-mxiks",
		async () => {
			const response = await request.get<ResponseDTO<AllMxikInfoDTO>>(`/card/all-mxiks`, {params: {lang: i18n.language}});

			return response;
		},
		{}
	);

	return query;
};

export default useGetAllMxiksInfoQuery;
