import { useQuery } from "react-query";

import { request } from "services/api";
import { shortDTO } from "types/utils";

type QueryData = shortDTO;
type Props = {
	code?: string;
	size?: number;
	page?: number;
	text?: string;
	download?: boolean;
	classCode?: string;
	lang?:string
	enabled?: boolean;
};

//code, size: 8, page: positionPage, enabled: code?.length === 5
const useGetClassQuery = ({ code, size, page, text, download, classCode, lang, enabled = false }: Props) => {
	const query = useQuery(
		// ["/class/short-info", code, size, page, text, download, classCode, enabled],
		["/class/short-info", size, code, page, text, download, classCode, lang],
		async () => {
			const response = await request.get<QueryData>(`/class/short-info`, {
				params: { groupCode: code, size, page, text, download, classCode, lang }
			});

			return response;
		},
		{ enabled, keepPreviousData: true }
	);

	return query;
};

export default useGetClassQuery;
