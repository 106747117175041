import { Modal } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { If } from 'react-utilify';
import { useTextSelection } from 'components/shared/Layout/ErrorsReport/components/useTextSelection';
import ErrorReportIkpu from 'components/shared/Layout/ErrorsReport/components/ErrorReportIkpu';
import ErrorReportText from 'components/shared/Layout/ErrorsReport/components/ErrorReportText';
import { useUser } from 'pages/Attribute/UserProvider';

interface IProps {
	children: React.ReactNode;
}

type TypeErrorReport = {
	selectedText: string | null;
	setSelectedText: (text: string) => unknown;
};

const ErrorReportContext = React.createContext<TypeErrorReport>({
	selectedText: null,
	setSelectedText: (text: string) => null,
});

function ErrorReport({ children }: IProps) {
	const [selectedText, setSelectedText] = useState<string | null>(null);

	return <ErrorReportContext.Provider value={{ selectedText, setSelectedText }}>{children}</ErrorReportContext.Provider>;
}

function Button({ onClick }: { onClick: () => unknown }) {
	const { t } = useTranslation();

	return (
		<button
			onClick={onClick}
			style={{
				boxShadow: '0px 20px 20px rgba(21, 37, 126, 0.22)',
			}}
			className='z-[999999] error-report hover:!bg-blue-700 duration-150 fixed top-[200px] right-0 bg-blue-default text-white !w-14 md:w-16 !px-1 !py-1 md:py-2 | flex flex-col items-center justify-center rounded-l-lg space-y-2 cursor-pointer'
		>
			<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'>
				<path
					fill='#fff'
					d='M9 12a.75.75 0 100 1.5.75.75 0 000-1.5zm8.003 1.102l-6.038-10.5a2.25 2.25 0 00-3.93 0l-6 10.5a2.25 2.25 0 001.92 3.398h12.09a2.25 2.25 0 001.958-3.398zm-1.298 1.5a.75.75 0 01-.66.383H2.955a.75.75 0 01-.66-.383.75.75 0 010-.75l6-10.5a.75.75 0 011.335 0l6.037 10.5a.75.75 0 01.038.765v-.015zM9 6a.75.75 0 00-.75.75v3a.75.75 0 101.5 0v-3A.75.75 0 009 6z'
				></path>
			</svg>

			<p className='text-[10px] text-center'>{t('Сообщить о ошибке')}</p>
		</button>
	);
}

function ErrorModal() {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState<0 | 1>(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const history = useHistory();
	const { selectedText } = React.useContext(ErrorReportContext);
	const user = useUser();

	const navigateToLogin = () => {
		history.push(`/sign-in`);
	};

	React.useLayoutEffect(() => {
		if (selectedText) {
			if (!user) {
				return navigateToLogin();
			}

			setIsModalOpen(true);
			setActiveTab(1);
		}
	}, [selectedText]);

	return (
		<>
			<Button
				onClick={() => {
					if (!user) {
						return navigateToLogin();
					}

					setIsModalOpen(!isModalOpen);
				}}
			/>
			<Modal onCancel={() => setIsModalOpen(false)} visible={isModalOpen} footer={null} className='!w-3/5'>
				<div className='!flex !flex-col !items-center !justify-center space-y-10'>
					<h3 className='text-3xl font-bold'>{t('Сообщите нам о найденной ошибке в системе')}</h3>

					<div className='flex space-x-5'>
						<button
							onClick={() => setActiveTab(0)}
							className={classNames(
								'text-base !py-2 !px-9 !flex !items-center !justify-center rounded-full cursor-pointer active:scale-95 duration-default',
								{
									'border border-blue-default !bg-transparent text-blue-default': activeTab !== 0,
									'!bg-gray-300': activeTab === 0,
								}
							)}
						>
							{t('Ошибка по ИКПУ')}
						</button>

						<button
							onClick={() => setActiveTab(1)}
							className={classNames(
								'text-base !py-2 !px-9 !flex !items-center !justify-center rounded-full cursor-pointer active:scale-95 duration-default',
								{
									'border border-blue-default !bg-transparent text-blue-default': activeTab !== 1,
									'!bg-gray-300': activeTab === 1,
								}
							)}
						>
							{t('Ошибка по сайту')}
						</button>

						<button
							onClick={() => {
								setIsModalOpen(false);
								history.push(`/?izmereniya=1`);
							}}
							className={classNames(
								'text-base !py-2 !px-9 !flex !items-center !justify-center rounded-full cursor-pointer active:scale-95 duration-default',
								{
									'border border-blue-default !bg-transparent text-blue-default': true,
								}
							)}
						>
							{t('Ошибка по единицы измерения')}
						</button>
					</div>

					<If is={activeTab === 0}>
						<ErrorReportIkpu onSuccess={() => setIsModalOpen(false)} />
					</If>

					<If is={activeTab === 1}>
						<ErrorReportText text={selectedText} onSuccess={() => setIsModalOpen(false)} />
					</If>
				</div>
			</Modal>
		</>
	);
}

export const ErrorReportTooltip = () => {
	const { t } = useTranslation();
	const { clientRect, isCollapsed, textContent, collapse } = useTextSelection();
	const { setSelectedText } = React.useContext(ErrorReportContext);

	const popupWidth = 160;
	const popupHeight = 30;

	if (!clientRect || !textContent) return null;

	return (
		<>
			<div
				style={{
					left: clientRect.x + clientRect.width / 2 - popupWidth / 2,
					top: clientRect.top - clientRect.height - popupHeight / 3 + window.scrollY,
					position: 'absolute',
					width: `${popupWidth}px`,
					height: `${popupHeight}px`,
					display: isCollapsed ? 'none' : 'block',
					visibility: isCollapsed ? 'hidden' : 'initial',
				}}
				className='bg-slate-900 border !select-none border-slate-500 text-white active:scale-95 z-[1000000] text-center rounded-md !flex items-center justify-center cursor-pointer hover:bg-opacity-80 duration-300'
				onClick={() => {
					setSelectedText(textContent);
					collapse();
				}}
			>
				{t('Сообщить о ошибке')}
				<svg
					fill='none'
					stroke='currentColor'
					strokeWidth={3}
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'
					aria-hidden='true'
					className='h-3 !ml-2'
				>
					<path strokeLinecap='round' strokeLinejoin='round' d='M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3' />
				</svg>
			</div>
		</>
	);
};

ErrorReport.TextSelectTooltip = ErrorReportTooltip;
ErrorReport.Modal = ErrorModal;

export default ErrorReport;
