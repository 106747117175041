import { lazy } from 'react';

const Home = lazy(() => import('pages/Home'));
const SearchList = lazy(() => import('pages/Search'));
const SearchItem = lazy(() => import('pages/Search/View'));
const Faq = lazy(() => import('pages/Faq'));
const Favorites = lazy(() => import('pages/Favorites'));
const SignIn = lazy(() => import('pages/Auth'));
const AllCategories = lazy(() => import('pages/Search/AllCategories'));
const Activities = lazy(() => import('pages/Activities'));
const FormPackagingView = lazy(() => import('pages/FormPackaging'));
const FormPackagingCreate = lazy(() => import('pages/FormPackaging/Create'));
const AdvancedSearch = lazy(() => import('pages/AdvancedSearch'));
const InProgress = lazy(() => import('pages/InProgress'));
const Steps = lazy(() => import('pages/Steps'));
const Attribute = lazy(() => import('pages/Attribute'));
const NotFound = lazy(() => import('pages/NotFound'));
const Category = lazy(() => import('pages/Category'));
const NewsList = lazy(() => import('pages/News/List'));
const NewsView = lazy(() => import('pages/News/View'));
const Cabinet = lazy(() => import('pages/Cabinet'));
const Tree = lazy(() => import('pages/Tree'));
const Catalog = lazy(() => import('pages/Catalog'));
const SearchDeep = lazy(() => import('pages/SearchDeep'));
const KomplektNabor = lazy(() => import('pages/komplekt-nabor'));

const routes = [
	{ path: '/komplekt-nabor', exact: true, component: KomplektNabor },
	{ path: '/search-deep', exact: true, component: SearchDeep },
	{ path: '/', exact: true, component: Home },
	// { path: "/search", exact: true, component: SearchTree },
	{ path: '/search/page/:id', exact: true, component: SearchList },
	{ path: '/search/all-categories/:id', exact: true, component: AllCategories },
	{ path: '/search/:id', exact: true, component: SearchItem },
	{ path: '/faq', exact: true, component: Faq },
	{ path: '/faq/:id', exact: true, component: Faq },
	{ path: '/favorites', exact: true, component: Favorites },
	{ path: '/sign-in', exact: true, component: SignIn },
	{ path: '/activities', exact: true, component: Activities },
	{ path: '/form-packaging/view/:id', exact: true, component: FormPackagingView },
	{ path: '/form-packaging/create/:id', exact: true, component: FormPackagingCreate },
	{ path: '/advanced-search', exact: true, component: AdvancedSearch },
	{ path: '/advanced-search/:tnVed', exact: true, component: AdvancedSearch },
	{ path: '/steps/:id', exact: true, component: Steps },
	{ path: '/category/:id', exact: true, component: Category },
	{ path: '/attribute/:id', exact: true, component: Attribute },
	{ path: '/in-progress', exact: true, component: InProgress },
	{ path: '/news', exact: false, component: NewsList },
	{ path: '/news-view/:id', exact: false, component: NewsView },
	{ path: '/cabinet', exact: false, component: Cabinet },
	{ path: '/Tree', exact: false, component: Tree },
	{ path: '/catalog', exact: false, component: Catalog },
	{ component: NotFound },
];

export default routes;
