import { Modal } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { For } from 'react-utilify';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnClickOutside } from 'components/VideoLessons/useClickOutside';

function VideoLessons() {
	const { t, i18n } = useTranslation();
	const btnRef = useRef(null);

	useOnClickOutside(btnRef, () => {
		setIsOpen(false);
	});

	const [isOpen, setIsOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [video, setVideo] = useState<{
		id: number;
		title: React.ReactNode;
		url?: string;
		embed?: {
			ru: string;
			uz_latn: string;
			uz_cyrl: string;
		};
	} | null>(null);

	const videos = [
		{
			id: 2,
			title: t('Qadoq yaratish'),
			embed: {
				ru: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/_ipJU0VvWrA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
				uz_latn: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/qG_E-rEVngU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
				uz_cyrl: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/qG_E-rEVngU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
			},
		},
		{
			id: 3,
			title: t("Yangi MXIK yaratish uchun so'rovnoma yuborish"),
			embed: {
				ru: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/f3ZLzmu1b9g?si=OG05zrpaMlhuatQ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_latn: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/9iQs3DfAZnE?si=GokwLenNvoefk9i3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
				uz_cyrl: `<iframe width="100%" height="500" src="https://www.youtube.com/embed/9iQs3DfAZnE?si=GokwLenNvoefk9i3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
			},
		},
	];

	const vidRef = useRef<HTMLVideoElement>(null);

	const stopVideo = () => {
		vidRef.current && vidRef.current.pause();
	};

	return (
		<div
			ref={btnRef}
			className='fixed !bottom-5 !right-5 md:!bottom-12 md:!right-16 w-48 md:w-56 space-y-2 flex flex-col items-end z-50'
		>
			<Modal
				title={<div className='text-xl'>{video?.title}</div>}
				visible={isModalOpen}
				footer={false}
				onCancel={() => {
					stopVideo();
					setIsModalOpen(false);
				}}
				width={1000}
				bodyStyle={{ padding: '0px 20px 10px' }}
			>
				{video &&
					isModalOpen &&
					(video.embed ? (
						<div
							dangerouslySetInnerHTML={{ __html: isModalOpen ? video.embed[i18n.language as keyof typeof video.embed] : '' }}
						/>
					) : (
						<video ref={vidRef} className='w-full !h-[450px]' controls>
							<source src={video.url} type='video/mp4' />
							Your browser does not support the video tag.
						</video>
					))}
			</Modal>

			{/* @ts-ignore */}
			<AnimatePresence>
				<div className='space-y-5 flex flex-col items-end z-50 py-3 w-56 overflow-y-hidden overflow-x-visible'>
					{isOpen ? (
						<For each={videos}>
							{(video, idx) => (
								<motion.div
									key={video.id}
									transition={{ duration: 0.5, type: 'spring' }}
									initial={{ y: 100 * (idx + 1), opacity: 0 }}
									animate={{ y: 0, opacity: 100 }}
									exit={{ y: -50 * (idx + 1), opacity: 0 }}
									onClick={() => {
										setIsOpen(false);
										setVideo(video);
										setIsModalOpen(true);
									}}
									className='bg-gray-50 rounded-full gap-2 w-full shadow-sm border justify-start flex border-gray-200 px-5 py-1 items-center active:scale-95 cursor-pointer'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='20'
										height='20'
										fill='none'
										stroke='currentColor'
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='1'
										className='text-gray-900 flex-shrink-0'
										viewBox='0 0 24 24'
									>
										<circle cx='12' cy='12' r='10'></circle>
										<path d='M10 8L16 12 10 16 10 8z'></path>
									</svg>

									<p className='text-gray-900 mb-0'>{video.title}</p>
								</motion.div>
							)}
						</For>
					) : null}
				</div>
			</AnimatePresence>

			<div
				onClick={() => setIsOpen(!isOpen)}
				className='bg-blue-default flex-grow-0 rounded-full gap-2  w-full shadow-lg px-5 h-10 flex items-center justify-center active:scale-95 cursor-pointer duration-150'
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					stroke='currentColor'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='2'
					className='text-white !h-5 !w-5 md:!h-6 md:!w-6'
					viewBox='0 0 24 24'
				>
					<circle cx='12' cy='12' r='10'></circle>
					<path d='M10 8L16 12 10 16 10 8z'></path>
				</svg>

				<p className='text-white mb-0 text-sm md:text-base font-medium md:font-bold text-center'>
					{t("Video qo'llanmalar")}
				</p>
			</div>
		</div>
	);
}

export default VideoLessons;
