import { useQuery } from "react-query";

import { request } from "services/api";
import { MeasurementInfoDTO, ResponseDTO } from "../../../types/utils";
import i18n from "../../../services/i18n";

const useGetMeasurementQuery = () => {
	const query = useQuery(
		"/card/products-having-measurement",
		async () => {
			const response = await request.get<ResponseDTO<MeasurementInfoDTO>>(`/card/products-having-measurement`, {
				params: { lang: i18n.language }
			});

			return response;
		},
		{}
	);

	return query;
};

export default useGetMeasurementQuery;
