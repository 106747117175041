import { useQuery } from 'react-query';

import { request } from 'services/api';
import { shortDTO } from 'types/utils';

type QueryData = shortDTO;
type Props = {
	code?: string;
	size?: number;
	page?: number;
	text?: string;
	lang?: string;
	download?: boolean;
	enabled?: boolean;
	groupCode?: string | number;
};
const useGetPositionQuery = ({ code, size, page, text, download, lang, groupCode, enabled = false }: Props) => {
	const query = useQuery(
		['/position/short-info', size, code, page, text, download, lang, groupCode],
		async () => {
			const response = await request.get<QueryData>(`/position/short-info`, {
				params: { classCode: code, size, page, text, download, lang, groupCode, type: 'POSITION' },
			});

			return response;
		},
		{ enabled, keepPreviousData: true }
	);

	return query;
};

export default useGetPositionQuery;
