import { Select, SelectProps } from 'antd';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { request } from 'services/api';

function NewSelectCategoryCode(props: SelectProps) {
	const { i18n, t } = useTranslation();

	const queryCategoryCodes = useQuery({
		queryKey: ['queryCategoryCodes'],
		queryFn: () => {
			return request({
				method: 'GET',
				url: `/category/get/all`,
				params: {
					pageSize: 1000,
					lang: i18n.language,
				},
			});
		},
	});

	const codeOptions = get(queryCategoryCodes, 'data.data.data', []).map((item: any) => ({
		label: `${item.code} - ${item.name}`,
		value: item.code,
	}));

	return (
		<Select
			size='small'
			allowClear
			showSearch
			className='!w-full !rounded-lg overflow-hidden'
			options={codeOptions}
			loading={queryCategoryCodes.status === 'loading'}
			placeholder={t("Toifalar bo'yicha saralash")}
			{...props}
		/>
	);
}

export default NewSelectCategoryCode;
