import ErrorReport from 'components/shared/Layout/ErrorsReport/components/ErrorReport';
import { useEffect } from 'react';

function ErrorsReport() {
	if (window.innerWidth < 700) {
		return null;
	}

	return (
		<ErrorReport>
			<ErrorReport.TextSelectTooltip />
			<ErrorReport.Modal />
		</ErrorReport>
	);
}

export default ErrorsReport;
