import axios, { AxiosError } from 'axios';

export const UserAPI = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});
export const request = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export const requestMain = axios.create({
	baseURL: process.env.REACT_APP_API_MAIN_URL,
});

const token = localStorage.getItem('token');

export const requestCashRegister = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

export const requestWithToken = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		common: {
			Authorization: `Bearer ${token}`,
		},
	},
});

export const requestTestApi = axios.create({
	baseURL: 'http://10.255.53.11:8093/cls-api',
	headers: {
		common: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	},
});

export const req = (service: 'cls' | 'cash' | 'cls-auth') => {
	if (service === 'cls') {
		return request;
	}

	if (service === 'cash') {
		return requestCashRegister;
	}

	if (service === 'cls-auth') {
		return requestWithToken;
	}

	return requestWithToken;
};

requestTestApi.interceptors.request.use(
	(config) => {
		// config.headers["Accept-Language"] = localStorage.getItem("i18nextLng");
		return config;
	},
	(error) => Promise.reject(error)
);

request.interceptors.request.use(
	(config) => {
		config.headers['Accept-Language'] = localStorage.getItem('i18nextLng');
		return config;
	},
	(error) => Promise.reject(error)
);

const lang = {
	ru: 'ru',
	uz_latn: 'uz',
	uz_cyrl: 'cyrl',
};

// @ts-expect-error
const Language = lang[localStorage.getItem('i18nextLng')];

requestMain.interceptors.request.use(
	(config) => {
		config.headers['Accept-Language'] = Language;
		return config;
	},
	(error) => Promise.reject(error)
);

requestWithToken.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token');
		config.headers['Authorization'] = `Bearer ${token}`;
		config.headers['Accept-Language'] = localStorage.getItem('i18nextLng');

		return config;
	},
	(error: AxiosError) => {
		if (error.response?.status === 401 && process.env.NODE_ENV === 'production') {
			localStorage.removeItem('token');
			window.location.pathname = '/sign-in';
		}

		return Promise.reject(error);
	}
);

export function generateAsyncInterceptor(request: any) {
	request.interceptors.request.use(
		function (config: any) {
			const token = localStorage.getItem('token');
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token;
			}
			return config;
		},
		(error: any) => Promise.reject(error)
	);
	request.interceptors.response.use(
		(config: any) => config,
		(error: any) => {
			if (error?.response?.status === 401) {
				throw error;
			}
			return Promise.reject(error);
		}
	);
}

generateAsyncInterceptor(requestCashRegister);
