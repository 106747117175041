import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions } from 'react-query';

import { request } from 'services/api';
import { paramsFieldFunc } from '../../../libs/ParamsField';

const useKameralAPIQuery = <Data = any, Error = any>(
  url: string,
  params: object = {},
  options: UseQueryOptions<AxiosResponse<Data>, Error> = {}
) => {
  const { i18n } = useTranslation();

  const paramsWithLang = { ...params, lang: i18n.language };

  paramsFieldFunc(paramsWithLang);

  return useQuery([url, params], () => request.get<Data>(url, { params: paramsWithLang }), { ...options });
};

export default useKameralAPIQuery;
