import React, { useEffect, useMemo, useState } from 'react';
import { Col, Collapse, message, Row, Spin } from 'antd';
import { useDebounce } from 'react-use';
import classes from './SearchTree.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import { useGeneralAPIQuery, useGetProfileQuery } from '../../hooks/queries';
import TreeItem from './components';
import useGetClassQuery from '../../hooks/queries/general/useGetClassQuery';
import useGetPositionQuery from '../../hooks/queries/general/useGetPositionQuery';
import useGetSubPositionQuery from '../../hooks/queries/general/useGetSubPositionQuery';
import useGetBrandQuery from '../../hooks/queries/general/useGetBrandQuery';
import useGetAttributeQuery from '../../hooks/queries/general/useGetAttributeQuery';
import { Input, Pagination } from '../shared';
import { useAddToFavoritesMutation } from '../../hooks/mutations';
import { Button } from 'components';

const { Panel } = Collapse;
const SearchTree = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const scrollRef = React.useRef(null);

	const [searchValue, setSearchValue] = useState('');
	const [debouncedText, setDebouncedText] = useState('');
	const [groupSize, setGroupSize] = useState(20);
	const [code, setCode] = useState<string>();

	const [classPage, setClassPage] = useState(0);
	const [positionPage, setPositionPage] = useState(0);
	const [subPositionPage, setSubPositionPage] = useState(0);
	const [brandPage, setBrandPage] = useState(0);
	const [attributePage, setAttributePage] = useState(0);

	useDebounce(
		() => {
			setDebouncedText(searchValue);
		},
		500,
		[searchValue]
	);

	//queries
	const userQuery = useGetProfileQuery();

	const categoriesQuery = useGeneralAPIQuery(
		'/group',
		{
			lang: i18n.language,
			size: groupSize,
			text: debouncedText,
		},
		{
			keepPreviousData: true,
			// enabled: debouncedText !== ""
		}
	);

	const categoriesData = useMemo(() => categoriesQuery?.data?.data?.data || [], [categoriesQuery?.data?.data]);

	const classQuery = useGetClassQuery({
		code,
		lang: i18n.language,
		// text: (code?.length === 3 && debouncedText) || '',
		size: 8,
		page: classPage,
		enabled: code?.length === 3,
	});
	// const positionQuery = useGetPositionQuery({ code, text: (code?.length === 5 && debouncedText) || "", download: true });
	const positionQuery = useGetPositionQuery({
		code,
		size: 8,
		page: positionPage,
		lang: i18n.language,
		enabled: code?.length === 5,
	});
	const subPositionQuery = useGetSubPositionQuery({
		code,
		lang: i18n.language,
		// text: (code?.length === 8 && debouncedText) || '',
		size: 8,
		page: subPositionPage,
		enabled: code?.length === 8,
	});
	const brandQuery = useGetBrandQuery({
		code,
		// text: (code?.length === 11 && debouncedText) || '',
		size: 8,
		page: brandPage,
		enabled: code?.length === 11,
	});
	const attributeQuery = useGetAttributeQuery({
		code,
		lang: i18n.language,
		// text: (code?.length === 14 && debouncedText) || '',
		size: 8,
		page: attributePage,
	});
	const classData = useMemo(() => classQuery?.data?.data?.data || [], [classQuery.isFetched, classPage]);
	const positionData = useMemo(() => positionQuery?.data?.data?.data || [], [positionQuery?.data?.data, positionPage]);
	const subPositionData = useMemo(
		() => subPositionQuery?.data?.data?.data || [],
		[subPositionQuery?.data?.data, subPositionPage]
	);
	const brandData = useMemo(() => brandQuery?.data?.data?.data || [], [brandQuery?.data?.data, brandPage]);
	const attributeData = useMemo(() => attributeQuery?.data?.data?.data || [], [attributeQuery?.data?.data, attributePage]);

	//mutations
	const addToFavoritesMutation = useAddToFavoritesMutation();

	const saveToFavorites = async (code: string) => {
		const mutationPromise = addToFavoritesMutation.mutateAsync({ mxik: code });
		const hide = message.loading(`${t('Юборилмоқда')}...`, 0);
		if (userQuery?.data?.data) {
			mutationPromise
				.then(() => {
					hide();
					message.success(
						<span>
							{t('Танланганларга мувофиқиятли қўшилди')} <a onClick={() => history.push('/favorites')}>{t('Перейти')}</a>
						</span>
					);
				})
				.catch(() => {
					hide();
					// message.error(t("Хатолик юз берди"));
				});
		} else {
			await localStorage.setItem('favorites', code);
			hide();
			message.info(t('Рўйхатдан ўтинг илтимос!'));
			history.push('/sign-in');
		}
	};

	const onTreeScroll = (event: any) => {
		event.preventDefault();
		const { target } = event;
		if (target.scrollTop >= target.scrollHeight - target.clientHeight - 30) {
			if (
				categoriesQuery?.data?.data?.data &&
				categoriesQuery.data?.data?.recordTotal > groupSize &&
				!categoriesQuery.isLoading
			) {
				setGroupSize(groupSize + 20);
			}
		}
	};
	const setCodeHandler = (code: any) => {
		!!code && setCode(!Array.isArray(code) ? code : code[0]);
		// let time = setTimeout(() => {
		//   // @ts-ignore
		//   if (scrollRef) {
		//     // @ts-ignore
		//     scrollRef?.current?.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'end' });
		//   }
		//   clearTimeout(time);
		// }, 500);
	};

	return (
		<div className={classes['search-tree']}>
			<Col span={24}>
				<Row justify={'space-between'} align={'middle'} className={'mb-30'}>
					<Col>
						<h2 className={classes.title}>{t('Электронный каталог')}</h2>
					</Col>
					<Col>
						<Link to={'/catalog'}>
							<Button type={'primary'} shape={'round'} size={'large'}>
								{t('Посмотреть в таблице')}
							</Button>
						</Link>
					</Col>
				</Row>
			</Col>
			<Input
				placeholder={`...`}
				// isSearch
				searchType={'secondary'}
				className={'mb-30'}
				onChange={(event) => setSearchValue(event.target.value)}
			/>
			<div className={classes.content} onScroll={onTreeScroll}>
				<div className='flex items-center justify-center !mb-5'>
					<Spin spinning={categoriesQuery.isFetching} />
				</div>

				<Collapse ghost onChange={(code) => setCodeHandler(code)} accordion>
					{categoriesData.length ? (
						categoriesData.map(
							(item: {
								mxikCount: string | undefined;
								name: string;
								code: string;
								count: string;
								logoSvg: string | undefined;
							}) => (
								<Panel
									header={
										<TreeItem
											isShowCount={true}
											count={item.mxikCount}
											name={item.name}
											code={item.code}
											icon={item.logoSvg}
										/>
									}
									key={item.code}
									className={classes['tree-item']}
								>
									<div ref={scrollRef} />
									<Collapse ghost onChange={(code) => !!code && setCode(!Array.isArray(code) ? code : code[0])} accordion>
										{classData?.map((item) => (
											<Panel
												header={
													<TreeItem
														count={item?.count}
														name={item.name}
														isShowCount={true}
														code={item.code}
														onAddFavorite={() => saveToFavorites(item.code)}
													/>
												}
												key={item.code}
												className={classes['tree-item']}
											>
												<Collapse
													ghost
													onChange={(code) => !!code && setCode(!Array.isArray(code) ? code : code[0])}
													accordion
												>
													{positionData.map((item) => (
														<Panel
															header={
																<TreeItem
																	count={item.count}
																	isShowCount={true}
																	name={item.name}
																	code={item.code}
																	onAddFavorite={() => saveToFavorites(item.code)}
																/>
															}
															key={item.code}
															className={classes['tree-item']}
														>
															<Collapse
																ghost
																onChange={(code) => !!code && setCode(!Array.isArray(code) ? code : code[0])}
																accordion
															>
																{subPositionData.map((item) => (
																	<Panel
																		header={
																			<TreeItem
																				count={item.count}
																				isShowCount={true}
																				name={item.name}
																				code={item.code}
																				onAddFavorite={() => saveToFavorites(item.code)}
																			/>
																		}
																		key={item.code}
																		className={classes['tree-item']}
																	>
																		<Collapse
																			ghost
																			onChange={(code) => !!code && setCode(!Array.isArray(code) ? code : code[0])}
																			accordion
																		>
																			{brandData.map((item) => (
																				<Panel
																					header={
																						<TreeItem
																							count={item.count}
																							isShowCount={true}
																							name={item.name}
																							code={item.code}
																							onAddFavorite={() => saveToFavorites(item.code)}
																						/>
																					}
																					key={item.code}
																					className={classes['tree-item']}
																				>
																					{attributeData?.map((item) => (
																						<Panel
																							header={
																								<TreeItem
																									name={item.name}
																									code={item.code}
																									onAddFavorite={() => saveToFavorites(item.code)}
																								/>
																							}
																							key={item.code}
																							className={classes['tree-item']}
																						/>
																					))}
																					{code?.length === 14 && (
																						<div>
																							<Row justify={'center'}>
																								<Col>
																									{attributeQuery?.data && attributeQuery?.data?.data?.recordTotal >= 8 && (
																										<div style={{ marginTop: 10 }}>
																											<Pagination
																												pageSize={8}
																												total={attributeQuery?.data?.data.recordTotal}
																												current={attributePage + 1}
																												onChange={(e) => setAttributePage(e - 1)}
																											/>
																										</div>
																									)}
																								</Col>
																							</Row>
																						</div>
																					)}
																				</Panel>
																			))}
																			{code?.length === 11 && (
																				<div>
																					<Row justify={'center'}>
																						<Col>
																							{brandQuery?.data && brandQuery?.data?.data.recordTotal >= 8 && (
																								<div style={{ marginTop: 10 }}>
																									<Pagination
																										pageSize={8}
																										total={brandQuery?.data?.data.recordTotal}
																										current={brandPage + 1}
																										onChange={(e) => setBrandPage(e - 1)}
																									/>
																								</div>
																							)}
																						</Col>
																					</Row>
																				</div>
																			)}
																		</Collapse>
																	</Panel>
																))}
																{code?.length === 8 && (
																	<div>
																		<Row justify={'center'}>
																			<Col>
																				{subPositionQuery?.data && subPositionQuery?.data?.data?.recordTotal >= 8 && (
																					<div style={{ marginTop: 10 }}>
																						<Pagination
																							pageSize={8}
																							total={subPositionQuery?.data?.data?.recordTotal}
																							current={subPositionPage + 1}
																							onChange={(e) => setSubPositionPage(e - 1)}
																						/>
																					</div>
																				)}
																			</Col>
																		</Row>
																	</div>
																)}
															</Collapse>
														</Panel>
													))}
													{code?.length === 5 && (
														<div>
															<Row justify={'center'}>
																<Col>
																	{positionQuery?.data && positionQuery?.data?.data?.recordTotal >= 8 && (
																		<div style={{ marginTop: 10 }}>
																			<Pagination
																				pageSize={8}
																				total={positionQuery?.data?.data?.recordTotal}
																				current={positionPage + 1}
																				onChange={(e) => setPositionPage(e - 1)}
																			/>
																		</div>
																	)}
																</Col>
															</Row>
														</div>
													)}
												</Collapse>
											</Panel>
										))}
										{code?.length === 3 && (
											<div>
												<Row justify={'center'}>
													<Col>
														{classQuery?.data && classQuery?.data?.data?.recordTotal >= 8 && (
															<div style={{ marginTop: 10 }}>
																<Pagination
																	pageSize={8}
																	total={classQuery?.data?.data?.recordTotal}
																	current={classPage + 1}
																	onChange={(e) => setClassPage(e - 1)}
																/>
															</div>
														)}
													</Col>
												</Row>
											</div>
										)}
									</Collapse>
								</Panel>
							)
						)
					) : (
						<div className={classes['not_fount_tree-item']}>
							<p>{t('Ничего не найдено')}</p>
						</div>
					)}
				</Collapse>
			</div>
		</div>
	);
};

export default SearchTree;
