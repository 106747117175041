import React from 'react';
import classes from './search-card.module.scss';
import { ReactComponent as StarIcon } from 'assets/svg/starIcon.svg';
import { ReactComponent as GroupBox } from 'assets/svg/group-box.svg';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import reactStringReplace from 'react-string-replace';

type Props = {
	item: any;
	searchValue?: any;
};

const SearchCard = ({ item, searchValue }: Props) => {
	const { t } = useTranslation();

	const mxikCode = get(item, 'mxikCode', '');
	const attributeName = get(item, 'attributeName', '');
	const groupName = get(item, 'groupName', '');
	const className = get(item, 'className', '');
	const positionName = get(item, 'positionName', '');
	const subPositionName = get(item, 'subPositionName', '');
	const mxikName = get(item, 'mxikName', '');
	const brandName = get(item, 'brandName', '');
	//code
	const classCode = get(item, 'classCode', '');
	const groupCode = get(item, 'groupCode', '');
	const positionCode = get(item, 'positionCode', '');
	const subPositionCode = get(item, 'subPositionCode', '');
	const stringReplace = (string: string) => reactStringReplace(string, searchValue, (match) => <b>{match}</b>);

	return (
		<>
			{attributeName ? (
				<div data-sec={true} className={classes.search_card}>
					<div className={classes.search_top}>
						<div className={classes.title_section}>
							<span>{mxikCode} </span> &#160;- &#160;<p>{stringReplace(mxikName || '')}</p>
						</div>
						{/*<StarIcon />*/}
					</div>
					<div className={classes['group-box']}>
						<GroupBox />
						<span>{t('Группа')}</span>
						:&#160;
						<p>
							{groupName}&#160; - &#160;({groupCode})
						</p>
					</div>
					<div data-size={20} className={classes['group-box']}>
						<GroupBox />
						<span>{t('Класс')}</span>
						:&#160;
						<p>
							{className}&#160; - &#160;({classCode})
						</p>
					</div>
					<div data-size={40} className={classes['group-box']}>
						<GroupBox />
						<span>{t('Позиция')}</span>
						:&#160;
						<p>
							{positionName}&#160; - &#160;({positionCode})
						</p>
					</div>

					<div data-size={60} className={classes['group-box']}>
						<GroupBox />
						<span>{t('Субпозиция')}</span>
						:&#160;
						<p>
							{subPositionName}&#160; - &#160;({subPositionCode})
						</p>
					</div>
					<div data-size={80} className={classes['group-box']}>
						<GroupBox />
						<span>{t('Бренд')}</span>
						:&#160;
						<p>{brandName}&#160;</p>
					</div>
					<div data-size={100} className={classes['group-box']}>
						<GroupBox />
						<span>{t('Атрибут')}</span>
						:&#160;
						<p>{attributeName}&#160;</p>
					</div>
				</div>
			) : (
				<div className={classes.search_card}>
					<div className={classes.search_top}>
						<div className={classes.title_section}>
							<span>{mxikCode} </span> &#160;- &#160;<p>{stringReplace(subPositionName || '')}</p>
						</div>
						<StarIcon />
					</div>
					<div className={classes['group-box']}>
						<GroupBox />
						<span>{t('Группа')}</span>
						:&#160;
						<p>
							{groupName}&#160; - &#160;({groupCode})
						</p>
					</div>
					<div data-size={20} className={classes['group-box']}>
						<GroupBox />
						<span>{t('Класс')}</span>
						:&#160;
						<p>
							{className}&#160; - &#160;({classCode})
						</p>
					</div>
					<div data-size={40} className={classes['group-box']}>
						<GroupBox />
						<span>{t('Позиция')}</span>
						:&#160;
						<p>
							{positionName}&#160; - &#160;({positionCode})
						</p>
					</div>
				</div>
			)}
		</>
	);
};

export default SearchCard;
