import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";

import { requestWithToken } from "services/api";

type MutationVariables = {};
type MutationError = AxiosError;
type MutationData = AxiosResponse;

const useFileDownloadMutation = (url: string) => {
	const mutation = useMutation<MutationData, MutationError, MutationVariables>(() =>
		requestWithToken.get(url, { responseType: "arraybuffer" })
	);

	return mutation;
};

export default useFileDownloadMutation;
