import React, { FC, ReactNode } from "react";
import { Collapse as AntCollapse, CollapseProps } from "antd";

import classes from "./Collapse.module.scss";

type Props = CollapseProps & {
	header: ReactNode;
	id: string;
	extra?: ReactNode;
};

const Collapse: FC<Props> = ({ header, id, extra, children, ...props }) => {
	const { Panel } = AntCollapse;
	return (
		<AntCollapse className={classes.collapse} {...props}>
			<Panel header={header} key={id} extra={extra && extra}>
				{children}
			</Panel>
		</AntCollapse>
	);
};

export default Collapse;
