import { useQuery, UseQueryOptions } from "react-query";

import { request } from "services/api";
import { shortDTO } from "types/utils";
import { AxiosResponse } from "axios";

type QueryData = shortDTO;
type Props<Data = any, Error = any> = {
	params: {
		lang?: string;
		brandName?: string;
		classCode?: string;
		full_name?: string;
		groupCode?: string;
		internalCode?: string;
		mxikCode?: string;
		pageNo: number;
		pageSize: number;
		positionCode?: string;
		subPositionCode?: string;
		surveyCategoryId?: string | number;
		categoryUnitNameUz?: string | number;
		checkUnitsNameUz?: string | number;
		checkPackageNameUz?: string | number;
		checkRecommendedCategoryUnitName?: string | number;
		checkRecommendedUnitsName?: string | number;
		lgota?: string | number;
		label?: string | number;
		useCard?: string | number;
		labelAndUseCard?: string | undefined | number,
		usePackage?: string | undefined | number,
		haveUnitsAndPackages?: string | undefined | number,
		recommendHaveUnitsAndPackages?: string | undefined | number,
		useCardOperator?: string | undefined | number,
		nonChangeAble?: string | undefined | number,
		nonChangeAbleUsePackage?: string | undefined | number,
	};
	
	options?: UseQueryOptions<AxiosResponse<Data>, Error>;
};
const useGetAttributeQuery = ({ params, options }: Props) => {
	const query = useQuery(
		["/attribute/web-katalog", params],
		async () => {
			const response = await request.get<QueryData>(`/attribute/web-katalog`, {
				params: { ...params }
			});

			return response;
		},
		{ ...options }
	);

	return query;
};

export default useGetAttributeQuery;
