import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";

import { request } from "services/api";

type MutationVariables = { type: string };
type MutationError = AxiosError;
type MutationData = AxiosResponse;

const useFileDownloadMutation = () => {
	const mutation = useMutation<MutationData, MutationError, MutationVariables>(({ type }) =>
		request.get(`/info/download/${type}`, { responseType: "arraybuffer" })
	);
	return mutation;
};

export default useFileDownloadMutation;
