import React, { FC } from "react";
import { Select as AntSelect, SelectProps, Spin } from "antd";
import cn from "classnames";

import "./Select.scss";
import { ReactComponent as SuffixIcon } from "assets/svg/arrowdown.svg";

type Props = SelectProps<any> & {
	className?: string;
	fullWidth?: boolean;
	label?: string;
	size?: string;
	background?: "white" | "lightBlue";
	loading?: boolean;
};

const Select: FC<Props> = ({ children, loading, label, className, fullWidth, size, background = "white", ...props }) => {
	const classNames = cn(
		background === "white" && "select",
		background === "lightBlue" && "blue-select",
		className && className,
		fullWidth && "full-width",
		size && size
	);

	return (
		<div style={{ position: "relative" }}>
			{label && <span className={"select-label"}>{label}</span>}

			<AntSelect
				className={classNames}
				{...props}
				suffixIcon={!loading ? <SuffixIcon /> : <Spin className={"mt--5"} size={"small"} />}>
				{children}
			</AntSelect>
		</div>
	);
};

export default Select;
