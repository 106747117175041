import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { requestWithToken } from 'services/api';
import { userDTO } from 'types/utils';

type QueryData = userDTO;
const useGetProfileQuery = () => {
	const { i18n } = useTranslation();
	const token = localStorage.getItem('token');
	const query = useQuery(
		'/user/current-user',
		async () => {
			const response = await requestWithToken.get<QueryData>(`/user/current-user`, {
				params: {
					lang: i18n.language,
				},
			});
			return response;
		},
		{ retry: false, enabled: !!token }
	);

	return query;
};

export default useGetProfileQuery;
