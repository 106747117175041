import { useQuery } from "react-query";

import { request } from "services/api";
import { shortDTO } from "types/utils";

type QueryData = shortDTO;
type Props = {
	code?: string;
	size?: number;
	page?: number;
	text?: string;
	download?: boolean;
	enabled?: boolean;
};
const useGetBrandQuery = ({ code, size, page, text, download, enabled = false }: Props) => {
	const query = useQuery(
		["/brand/short-info", code, size, page, text, download, enabled],
		async () => {
			const response = await request.get<QueryData>(`/brand/short-info`, {
				params: { subPositionCode: code, size, page, text, download }
			});

			return response;
		},
		{ enabled, keepPreviousData: true }
	);

	return query;
};

export default useGetBrandQuery;
