import { useMutation } from 'react-query';

import { requestWithToken } from 'services/api';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

const useAddToFavoritesMutation = () => {
	const { t } = useTranslation();

	return useMutation({
		mutationKey: ['company-products/add'],
		mutationFn: ({ mxik }: any) => {
			if (mxik.length === 14) {
				return requestWithToken.post(`/company-products/add/from-brand/${mxik}`);
			} else {
				return requestWithToken.post(`/company-products/add/${mxik}`);
			}
		},
		onError: (res: Error) => {
			const status = get(res, 'status');

			const resMessage = get(res, 'response.data', null);

			if (
				res.message !== 'Request failed with status code 401' &&
				resMessage !==
					"Siz tomondan realizatsiya qiladigan mahsulotlar to'liq identifikatsiyalangan MXIK kodlariga ega bo'lganligi sababli, asosiy kodlardan foydalanish cheklangan."
			) {
				message.error(
					t(
						`Sizning faoliyat turingiz uchun ushbu MXIKdan foydalanish cheklangan.  Ma'lumot uchun 71 244-98-09 (ichki raqam 01573, 01564) raqamlariga murojaat qiling.`
					)
				);
			}
		},
	});
};

export default useAddToFavoritesMutation;
