import React, { FC, ReactNode } from "react";
import cn from "classnames";
import classes from "./InfoCard.module.scss";
import { Link } from "react-router-dom";

type Props = {
	icon?: string;
	direction?: string;
	text?: string;
	path: string;
	bg?: boolean;
	key?: number;
	smText?: string;
	width?: string;
	className?: string;
	onClick?: () => void;
	empty?: boolean;
	extra?: ReactNode;
};

const InfoCard: FC<Props> = ({ icon, empty, className, extra, direction = "horizontal", onClick, text, path, bg, smText, ...props }) => {
	const classNames = cn(classes["info-card"], classes[`${direction}`], bg && classes.bg, empty && classes.empty, className && className);

	return (
		<Link to={path} className={classNames} {...props} onClick={onClick}>
			{extra && <div className={classes.extra}>{extra}</div>}
			{icon && (
				<div className={classes["info-card__icon"]}>
					<img src={icon} alt={text} />
				</div>
			)}
			{text && <h5>{text}</h5>}
			{smText && <p>{smText}</p>}
		</Link>
	);
};

export default InfoCard;
