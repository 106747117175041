import InfoCard from "./InfoCard";
import ServiceBlockCard from "./ServiceBlockCard";
import CategoryCard from "./CategoryCard";
import EmptyCard from "./EmptyCard";
import ImageCard from "./ImageCard";
import NewsCard from "./NewsCard";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	InfoCard,
	ServiceBlockCard,
	CategoryCard,
	EmptyCard,
	ImageCard,
	NewsCard
};
