import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosResponse } from 'axios';

import { requestWithToken } from 'services/api';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';

const useKameralAPIMutation = <Variables = any, Response = any, Error = any>(
	url: string,
	method: 'post' | 'get' | 'put' | 'patch' | 'delete',
	options: UseMutationOptions<AxiosResponse<Response>, Error, Variables> = {}
) => {
	const { i18n } = useTranslation();
	return useMutation<AxiosResponse<Response>, Error, Variables>(
		(variables) => {
			const { captcha, guid, ...rest } = variables as any;

			const response = requestWithToken.request({
				method,
				url,
				data: rest,
				params: {
					lang: i18n.language,
					captcha: get(variables, 'captcha', null),
					guid: get(variables, 'guid', null),
				},
			});
			return response;
		},
		{ ...options }
	);
};

export default useKameralAPIMutation;
