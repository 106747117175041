import {useGeneralAPIQuery} from "hooks/queries";
import {InformationMxikDTO} from "types/utils";
import get from "lodash/get";
import {useMemo} from "react";

const searchUrls = {
    1: '/mxik/search/by-params',
    2: '/mxik/search-subposition',
    3: '/mxik/search/by-params',
    4: '/mxik/search/by-params'
}

const paramsKey = {
    "1": "text",
    "2": "search_text",
    "3": "mxikCode",
    "4": "gtin"
}
type Props = {
    radioValue: "1" | "2" | "3" | "4",
    text: string,
    size: number,
    activeTab: "right" | "wrong",
    setSearchSize: (value: number) => void;
}

const useSearchQuery = ({radioValue, text, size, activeTab, setSearchSize}: Props) => {

    const searchQuery = useGeneralAPIQuery<InformationMxikDTO>(
        searchUrls[radioValue],
        {
            [paramsKey[radioValue]]: text,
            size: size,
            lang: localStorage.getItem("i18nextLng")
        },
        {
            keepPreviousData: true,
            enabled: !!text
        }
    );


    const searchBySymbolQuery = useGeneralAPIQuery<InformationMxikDTO>(
        '/mxik/search-symbol',
        {
            search_text: text,
            size: size,
            lang: localStorage.getItem("i18nextLng")
        },
        {
            keepPreviousData: true,
            enabled: !!text && radioValue === "2"
        }
    );
    const wrongLength = useMemo(
        () => searchBySymbolQuery?.data?.data?.data?.totalElements || 0,
        [searchBySymbolQuery?.data?.data, radioValue]
    );

    const rightLength = useMemo(
        () => searchQuery?.data?.data?.data?.totalElements || 0,
        [searchQuery?.data?.data, radioValue]);



    const onPopupScroll = (event: any) => {
        event.preventDefault();
        const { target } = event;
        if (target.scrollTop >= target.scrollHeight - target.clientHeight - 28) {
            if (radioValue === "2") {
                if (activeTab === "wrong"){
                    if (wrongLength > size &&
                        !searchBySymbolQuery.isFetching
                    ) {
                        setSearchSize(size + 20);
                    }
                }else {
                    if (
                        rightLength > size &&
                        !searchQuery.isFetching
                    ) {
                        setSearchSize(size + 20);
                    }
                }
            }if (
                rightLength > size &&
                !searchQuery.isFetching
            ) {
                setSearchSize(size + 20);
            }
        }
    };


    return {
        isFetched: searchQuery.isFetched || searchBySymbolQuery.isFetched,
        isLoading: searchQuery.isFetching || searchBySymbolQuery.isFetching,
        data: get(searchQuery, "data.data.data.content", []) || [],
        symbolData: get(searchBySymbolQuery, "data.data.data.content", []) || [],
        wrongLength,
        rightLength,
        onPopupScroll


    }
};

export default useSearchQuery;