import React, { FC, ReactElement, useState } from 'react';
import { Breadcrumb, Col, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import classes from './PageHead.module.scss';
import Container from 'components/shared/Container';
import { Button, Search } from 'components';
import { searchDTO } from 'types/utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PrevIcon } from 'assets/svg/left-chevron.svg';
import { Input } from '../index';
import AntSwitch from '../Switch';

type ButtonProps = {
	title: string;
	id: number;
	path: string;
	icon: ReactElement;
	handleClick: () => void;
};

type BreadCrumb = {
	id: number;
	title: string;
	path?: string;
};

type Props = {
	rightLength?: number;
	title: string;
	buttons?: Array<ButtonProps>;
	breadCrumb?: Array<BreadCrumb>;
	activeTab?: number;
	btnOnclick?: (id: number) => void;
	className?: string;
	search?: boolean;
	withFilter?: boolean;
	prevButton?: boolean;
	handlePrevButton?: () => void;
	searchLabel?: string;

	radioValue?: string;
	setRadioValue?: (value: string) => void;
	searchValue?: string;
	setSearchValue?: (value: string) => void;
	isLoading?: boolean;
	setIsLoading?: (value: boolean) => void;
	searchData?: searchDTO;
	input?: boolean;
	onTouched?: (value: boolean) => void;
	maxLength?: number;
	searchActiveTab?: 'right' | 'wrong';
	setSearchActiveTab?: (value: 'right' | 'wrong') => void;
	onPopupScroll?: (value: any) => void;
};

const PageHead: FC<Props> = (props) => {
	const { t } = useTranslation();
	const {
		title,
		searchLabel,
		withFilter,
		search,
		buttons,
		prevButton,
		handlePrevButton,
		breadCrumb,
		className,
		radioValue,
		setRadioValue,
		searchValue,
		setSearchValue,
		isLoading,
		setIsLoading,
		searchData,
		input,
		onTouched,
		maxLength,
		searchActiveTab,
		rightLength,
		setSearchActiveTab,
		onPopupScroll,
	} = props;
	const classNames = cn(classes['page-head'], className && className);
	const [activeTab, setActiveTab] = useState<'right' | 'wrong'>('right');

	return (
		<div className={classNames}>
			<Container>
				<div className={classes['page-head__content']}>
					<h1>{title}</h1>
					<Breadcrumb separator={'-'} className={'mt-20 mb-30 flex items-center justify-center'}>
						{prevButton && (
							<Button icon={<PrevIcon />} onClick={() => handlePrevButton && handlePrevButton()} tab className={'mr-30'}>
								{t('Назад')}
							</Button>
						)}
						{breadCrumb &&
							breadCrumb.map((item, index) => (
								<Breadcrumb.Item className={classes['bread_crumb_text']} key={item.id + index}>
									{item.path ? <Link to={item.path}>{item.title}</Link> : item.title}
								</Breadcrumb.Item>
							))}
					</Breadcrumb>

					<Row>
						<Space size={'large'} style={{ margin: '0 auto' }}>
							{buttons &&
								buttons.map((item) => (
									<Button
										key={item.id}
										icon={item.icon}
										size={'large'}
										className={'mb-40'}
										type={'primary'}
										shape={'round'}
										onClick={() => item.handleClick()}
									>
										{item.title}
									</Button>
								))}
						</Space>
						{search && (
							<Col md={24} className={'mb-50'}>
								<Search
									onPopupScroll={onPopupScroll}
									searchValue={searchValue}
									searchValueChange={setSearchValue}
									searchLoading={isLoading}
									setLoading={setIsLoading}
									searchData={searchData}
									withFilter={withFilter}
									radioValue={radioValue}
									radioValueChange={setRadioValue}
									searchLabel={searchLabel}
									activeTab={searchActiveTab || activeTab}
									setActiveTab={setSearchActiveTab || setActiveTab}
									rightLength={rightLength || 0}
									wrongLength={rightLength || 0}
								/>
							</Col>
						)}

						{input && (
							<Col span={24} className={'mb-50'}>
								<Input
									placeholder={`...`}
									label={`${searchLabel ? searchLabel : t('Поиск по наименованию')}`}
									isSearch
									searchType={'secondary'}
									value={searchValue}
									onChange={(e) => setSearchValue && setSearchValue(e.target.value)}
									maxLength={maxLength}
									onFocus={() => onTouched && onTouched(true)}
									onBlur={() => onTouched && onTouched(false)}
								/>
							</Col>
						)}
					</Row>
				</div>
			</Container>
		</div>
	);
};

export default PageHead;
