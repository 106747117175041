import React, { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, Form, InputNumber, message, Row, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/es/upload/interface';
import cn from 'classnames';
import classes from './AddNewProduct.module.scss';
import { Input, MaskedInput, Modal, Select, TextArea } from 'components/shared';
import { ReactComponent as PlusIcon } from 'assets/svg/pluse.svg';
import { Button } from 'components';
import { Nullable, searchDTO } from 'types/utils';
import { useGeneralAPIMutation } from 'hooks/mutations';
import { useGetAllUnitsQuery, useGetExemptionQuery, useGetStepsQuery } from 'hooks';
import { getFieldNameByLang } from 'libs/getFieldNameByLang';
import { useGeneralAPIQuery, useGetProfileQuery } from 'hooks/queries';
import { useHistory } from 'react-router-dom';
import useDebounceHook from 'libs/useDebounceHook';
import classNames from 'classnames';
import AutoCompleteInput from '../../pages/Cabinet/component/AutoCompleteInput/AutocompelteInput';
import usePackageAPIQuery from '../../hooks/queries/general/usePackageAPIQuery';
import Captcha from 'components/Captcha';
import get from 'lodash.get';

type Props = {
	isModalVisible: boolean;
	setIsModalVisible: (value: boolean) => void;
	answers?: number[];
};

type formTypes = Nullable<{
	phone: string;
	category_id: number;
	class?: number;
	subClass?: number;
	position?: number;
	productName: string;
	productBrand?: string;
	productModel?: string;
	productInfo: string;
	tnved: string;
	internationalCode?: string;
	unit_id?: string;
	packagingName?: string;
	exemption?: boolean;
	privilegeCode: number;
	captcha: string;
	fileGuid?: UploadFile[];
}>;

function beforeUpload(file: UploadFile) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
		return Upload.LIST_IGNORE;
	}
	const isLt2M = file.size && file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
		return Upload.LIST_IGNORE;
	}
	return isJpgOrPng && !!isLt2M;
}

const AddNewProduct: FC<Props> = ({ isModalVisible, setIsModalVisible, answers }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const history = useHistory();

	const [formValues, setFormValues] = useState<formTypes | undefined>();
	const [typeForm, setTypeForm] = useState<any>(1);
	const [mxik, setMxik] = useState('');
	const debouncedText = useDebounceHook(mxik, 500);
	const [mxikValue, setMxikValue] = useState('');
	const [mxikName, setMxikName] = useState('');

	//queries
	const userQuery = useGetProfileQuery();
	const getCategoryQuery = useGetStepsQuery(0, isModalVisible);
	const getClassQuery = useGetStepsQuery(formValues?.category_id || (answers && answers[0]) || 0, !!formValues?.category_id);
	const getSubClassQuery = useGetStepsQuery(formValues?.class || (answers && answers[1]) || 0, !!formValues?.class);
	const getPositionQuery = useGetStepsQuery(formValues?.subClass || (answers && answers[2]) || 0, !!formValues?.subClass);
	const getAllUnitsQuery = useGetAllUnitsQuery(0, 1000);
	const getExemptionQuery = useGetExemptionQuery(formValues?.exemption || false);
	const searchMxikQuery = useGeneralAPIQuery<searchDTO>(
		'/mxik/search/by-params',
		{
			gtin: debouncedText || '',
		},
		{
			// keepPreviousData: true,
			enabled: !!debouncedText,
		}
	);
	//mutations
	const addNewProductMutations = useGeneralAPIMutation('/user-products/add-product', 'post');
	const addProductUnitsMutations = useGeneralAPIMutation('/user-product-units/user-product-units', 'post');
	const resultsSearch = useMemo(() => {
		let data = searchMxikQuery?.data?.data?.data?.content[0]?.mxikCode;
		if (mxik && data) {
			return data;
		} else return false;
	}, [searchMxikQuery?.data, mxik]);
	console.log({ mxikName });

	const getPackageQuery = usePackageAPIQuery({ mxik: mxikValue });

	const packageOption = useMemo(() => {
		return Array.isArray(getPackageQuery?.data?.data.packages)
			? getPackageQuery?.data?.data.packages.map((i) => ({
					label: i?.containerName ? `${i?.containerName ? i?.containerName?.toUpperCase() : ''} - ${i?.name}` : `${i?.name}`,
					value: i.code,
			  }))
			: [];
	}, [getPackageQuery?.data?.data]);

	useEffect(() => {
		if (isModalVisible && userQuery.isError) {
			message.info(t('Рўйхатдан ўтинг илтимос!'));
			history.push('/sign-in');
		}
	}, [userQuery.isFetching, isModalVisible]);

	const onSubmit = (values: formTypes) => {
		const {
			phone,
			productName,
			productBrand,
			productModel,
			productInfo,
			tnved,
			internationalCode,
			unit_id,
			packagingName,
			fileGuid,
			position,
			privilegeCode,
			captcha,
		} = values;
		const mutationPromise = addNewProductMutations.mutateAsync({
			phone,
			category_id: position,
			productName,
			productBrand,
			productModel,
			productInfo,
			tnved: tnved?.toString(),
			internationalCode: internationalCode?.toString(),
			unit_id: unit_id && parseInt(unit_id),
			packagingName,
			privilegeCode,
			fileGuid: fileGuid && fileGuid[0].response,
			captcha,
			guid: captchaGuid,
		});

		const hide = message.loading(t('Юкланмоқда...'), 0);

		mutationPromise
			.then(() => {
				hide();
				setFormValues(undefined);
				form.resetFields();

				message.success(t('Информация успешно отправляет'));
			})
			.catch((error) => {
				hide();

				const errorMessage = get(error, 'response.data', null);

				if (errorMessage === `Incorrect captcha code!`) {
					return message.error(t('Символы в рисунках не правильно		'));
				}

				if (errorMessage === 'Packaging is limited for this mix code!') {
					return message.error(
						t(
							`Ushbu MXIK kodida qadoq yaratish cheklangan.  Ma'lumot uchun 71 244-98-09 (ichki raqam 01951) raqamiga murojaat qiling.`
						)
					);
				}

				message.error(t('Хатолик юз берди'));
			});
	};

	const onSubmitFailed = (errorInfo: any) => {
		message.error(`${t('Хатолик юз берди')} ${errorInfo.errorFields[0].errors}`);
	};

	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const onCancel = async () => {
		localStorage.removeItem('isModalView');
		setIsModalVisible(false);
	};

	const classNamesMXIK = cn(classes['m-x_input'], searchMxikQuery.isLoading && classes['btn-loading']);

	const onSubmitHandler = (values: any) => {
		const { clUnitId, description, fileGuid, newUnitName, phone, captcha } = values;
		const mutationPromise = addProductUnitsMutations.mutateAsync({
			clUnitId,
			mxikName,
			description,
			mxikCode: mxikValue,
			newUnitName,
			phone,
			fileGuid: fileGuid && fileGuid[0].response,
			captcha,
			guid: captchaGuid,
		});
		const hide = message.loading(t('Юкланмоқда...'), 0);
		mutationPromise
			.then(() => {
				hide();
				message.success(t('Информация успешно отправляет'));

				setIsModalVisible(false);
				setFormValues(undefined);
				form.resetFields();
			})
			.catch((error) => {
				hide();

				const errorMessage = get(error, 'response.data.error', null);

				if (errorMessage === `captcha parameter is missing`) {
					return message.error(t('Символы в рисунках не правильно		'));
				}

				message.error(t('Хатолик юз берди'));
			});
	};

	const onSelect = (data: any) => {
		setMxikValue(data);
	};
	const [captchaGuid, setCaptchaGuid] = useState<string | null>(null);

	useEffect(() => {
		if (getPackageQuery.isSuccess) {
			const { mxikName }: any = getPackageQuery?.data?.data;

			setMxikName(mxikName);
			form.setFieldValue('name', mxikName || '');
		}
	}, [getPackageQuery.data]);

	return (
		<Modal
			className={classes.modal}
			// bodyStyle={{ height: '63rem' }}
			centered
			title={typeForm === 1 ? t('Отправить заявку') : t('Отправить заявку')}
			visible={isModalVisible}
			onCancel={() => onCancel()}
		>
			<div className={classes.button_section}>
				<div
					onClick={() => setTypeForm(1)}
					className={classNames(
						classes.button,
						'!w-48 flex items-center justify-center !font-normal',
						typeForm === 1 ? classes.button_active : ''
					)}
				>
					{t('МХИК')}
				</div>
				<div
					onClick={() => setTypeForm(2)}
					className={classNames(
						classes.button,
						'!w-48 flex items-center justify-center !font-normal',
						typeForm === 2 ? classes.button_active : ''
					)}
				>
					{t('Улчов бирлик')}
				</div>
			</div>
			{typeForm === 1 ? (
				<Form
					form={form}
					layout={'vertical'}
					onFinish={onSubmit}
					onFinishFailed={onSubmitFailed}
					className={classes['add-new-product']}
					onValuesChange={(changedValues, allValues) => setFormValues(allValues)}
					initialValues={
						answers && {
							category_id: answers[0],
							class: answers[1],
							subClass: answers[2],
							position: answers[3],
						}
					}
				>
					<Row gutter={20} className={'mt-30'}>
						<Col md={12} xs={24}>
							<Form.Item
								label={t('Телефон рақами')}
								name={'phone'}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<MaskedInput mask={'+998 (00) 000 00 00'} />
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								name='category_id'
								label={t('Группа')}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<Select
									allowClear
									size={'large'}
									placeholder={t('Танланг')}
									options={getCategoryQuery?.data?.data?.data?.map((item) => ({
										key: item.code,
										label: item.name,
										value: item.code,
									}))}
									onSelect={() => {
										form.resetFields(['class', 'subClass', 'position']);
									}}
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								name='class'
								label={t('Класс')}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<Select
									size={'large'}
									placeholder={t('Танланг')}
									disabled={!formValues?.category_id}
									options={getClassQuery?.data?.data?.data?.map((item) => ({
										key: item.code,
										label: item.name,
										value: item.code,
									}))}
									onSelect={() => {
										form.resetFields(['subClass', 'position']);
									}}
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
								name='subClass'
								label={t('Подкласс')}
							>
								<Select
									allowClear
									size={'large'}
									placeholder={t('Танланг')}
									disabled={!formValues?.class}
									options={getSubClassQuery?.data?.data?.data?.map((item) => ({
										key: item.code,
										label: item.name,
										value: item.code,
									}))}
									onSelect={() => {
										form.resetFields(['position']);
									}}
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								name='position'
								label={t('Позиция')}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<Select
									allowClear
									size={'large'}
									placeholder={t('Танланг')}
									disabled={!formValues?.subClass}
									options={getPositionQuery?.data?.data?.data?.map((item) => ({
										key: item.code,
										label: item.name,
										value: item.code,
									}))}
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24}>
							<Form.Item
								label={formValues?.category_id !== 2 && formValues?.category_id !== 3 ? t('Название товара') : t('Услуги')}
								name={'productName'}
								rules={[
									{ required: true, message: t('Илтимос маълумотларни киритинг') },
									{
										pattern: new RegExp('[a-zA-Zа-яА-Я]+'),
										message: `${t('Название товара')} ${t('продукта состоит не только из цифр')}`,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						{formValues?.category_id !== 2 && formValues?.category_id !== 3 && (
							<>
								<Col md={12} xs={24}>
									<Form.Item label={t('Бренд')} name={'productBrand'}>
										<Input />
									</Form.Item>
								</Col>
								<Col md={12} xs={24}>
									<Form.Item label={t('Модель')} name={'productModel'}>
										<Input />
									</Form.Item>
								</Col>
							</>
						)}

						<Col md={24} xs={24}>
							<Form.Item
								label={
									formValues?.category_id !== 2 && formValues?.category_id !== 3
										? t('Описание товара')
										: t('Дополнительная информация')
								}
								name={'productInfo'}
								rules={[
									{ required: true, message: t('Илтимос маълумотларни киритинг') },
									{
										pattern: new RegExp('[a-zA-Zа-яА-Я]+'),
										message: `${t('Описание товара')} ${t('продукта состоит не только из цифр')}`,
									},
								]}
							>
								<TextArea maxLength={1000} />
							</Form.Item>
						</Col>

						{formValues?.category_id !== 2 && formValues?.category_id !== 3 && (
							<>
								<Col md={12} xs={24}>
									<Form.Item
										label={t('Код ТНВЭД')}
										name={'tnved'}
										rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
									>
										<InputNumber maxLength={10} />
									</Form.Item>
								</Col>
								<Col md={12} xs={24}>
									<Form.Item
										label={t('Штрих код')}
										name={'internationalCode'}
										validateStatus={resultsSearch ? 'error' : ''}
										help={resultsSearch ? `${resultsSearch} ${t('MXIK код яратилган')}` : null}
									>
										<InputNumber
											onChange={(event) => {
												form.setFieldsValue({
													...form.getFieldsValue(),
													internationalCode: event,
												});
												setMxik(event as string);
											}}
											className={classNamesMXIK}
											maxLength={20}
										/>
									</Form.Item>
								</Col>
							</>
						)}

						<Col md={formValues?.category_id !== 2 && formValues?.category_id !== 3 ? 12 : 24} xs={24}>
							<Form.Item name='unit_id' label={t('Единица имерения')}>
								<Select
									allowClear
									size={'large'}
									placeholder={t('Танланг')}
									options={getAllUnitsQuery?.data?.data?.data?.map((item) => ({
										key: item.id,
										label: item.name,
										value: item.id,
									}))}
								/>
							</Form.Item>
						</Col>
						{formValues?.category_id !== 2 && formValues?.category_id !== 3 && (
							<Col md={12} xs={24}>
								<Form.Item label={t('Упаковка товара')} name={'packagingName'}>
									<Input />
								</Form.Item>
							</Col>
						)}
						<Col md={12} xs={24}>
							<div className='flex items-center justify-between space-x-5'>
								<Captcha onCaptchaChange={({ captchaGuid }) => setCaptchaGuid(captchaGuid)} />
								<div className='w-full relative'>
									<Form.Item
										rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
										// label={t('captcha')}
										name={'captcha'}
										className='w-full mb-0'
									>
										<Input placeholder={t('Напишите символ из рисунка')} className='w-full mb-0' />
									</Form.Item>
								</div>
							</div>
						</Col>
						<Col span={24}>
							<Form.Item name='exemption' valuePropName='checked'>
								<Checkbox>{t('Льгота')}</Checkbox>
							</Form.Item>
						</Col>
						{formValues?.exemption && (
							<Col md={24} xs={24}>
								<Form.Item name='privilegeCode' label={t('Имтиёз турини танланг')}>
									<Select
										allowClear
										size={'large'}
										placeholder={t('Танланг')}
										options={getExemptionQuery?.data?.data?.flatMap((item) => ({
											value: item.id,
											// @ts-ignore
											label: item[getFieldNameByLang('name')],
											key: item.id,
										}))}
									/>
								</Form.Item>
							</Col>
						)}

						{formValues?.category_id === 1 && (
							<Col md={24} xs={24}>
								<Form.Item
									name='fileGuid'
									valuePropName='fileList'
									getValueFromEvent={normFile}
									noStyle
									rules={[{ required: true, message: t('Пожалуйста загрузите фото') }]}
								>
									<Upload
										beforeUpload={beforeUpload}
										action={`${process.env.REACT_APP_API_URL}/file/upload`}
										headers={{
											Authorization: 'Bearer ' + localStorage.getItem('token'),
										}}
										accept='image/png, image/gif, image/jpeg, image/jpg'
										data={(file: UploadFile) => {
											return {
												content_type: file.type,
												file_size: file.size,
												original_filename: file.name,
											};
										}}
										listType='picture'
										multiple
									>
										<Button size={'large'} icon={<PlusIcon />}>
											{t('Загрузить фото')} <span style={{ fontSize: '10px', paddingLeft: '5px' }}>(10 mb)</span>
										</Button>
									</Upload>
								</Form.Item>
							</Col>
						)}

						<Col md={24} xs={24}>
							<div className={classes.buttons}>
								<Button onClick={() => setIsModalVisible(false)} type={'text'} size={'large'} shape={'round'}>
									{t('Отменить')}
								</Button>
								<Button
									type={'primary'}
									size={'large'}
									shape={'round'}
									htmlType={'submit'}
									loading={addNewProductMutations.isLoading}
								>
									{t('Отправить')}
								</Button>
							</div>
						</Col>
					</Row>
				</Form>
			) : (
				<Form
					form={form}
					layout={'vertical'}
					onFinish={onSubmitHandler}
					onFinishFailed={onSubmitFailed}
					className={classes['add-new-product']}
					onValuesChange={(changedValues, allValues) => setFormValues(allValues)}
					initialValues={
						answers && {
							category_id: answers[0],
							class: answers[1],
							subClass: answers[2],
							position: answers[3],
						}
					}
				>
					<Row gutter={[40, 20]} className={'mt-30'}>
						<Col md={24} xs={2}>
							<Form.Item
								label={mxikValue ? t('МХИК') : ''}
								name={'mxikCode'}
								rules={[{ message: t('Илтимос маълумотларни киритинг') }]}
							>
								<AutoCompleteInput isEdite={false} mxikValue={mxikValue} onSelect={onSelect} />
							</Form.Item>
						</Col>
						<Col md={12} xs={12}>
							<Form.Item
								name={'name'}
								label={t('Каталогга мувофик товарлар/хизматлар номи')}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col md={12} xs={12}>
							<Form.Item
								label={t('Телефон рақами')}
								name={'phone'}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<MaskedInput mask={'+998 (00) 000 00 00'} />
							</Form.Item>
						</Col>

						<Col md={formValues?.category_id !== 2 && formValues?.category_id !== 3 ? 12 : 24} xs={24}>
							<Form.Item
								name='clUnitId'
								label={t('Единица имерения')}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<Select allowClear size={'large'} placeholder={t('Танланг')} options={packageOption || []} />
							</Form.Item>
						</Col>

						{/* yangi qoshganim*/}
						<Col md={formValues?.category_id !== 2 && formValues?.category_id !== 3 ? 12 : 24} xs={24}>
							<Form.Item
								name='newUnitName'
								label={t('Таклиф қилинаётган ўлчов бирлиги')}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<Select
									allowClear
									size={'large'}
									placeholder={t('Танланг')}
									options={getAllUnitsQuery.data?.data?.data?.map((item) => ({
										key: item.id,
										label: item.name,
										value: item.name,
									}))}
								/>
							</Form.Item>
						</Col>

						<Col md={24} xs={24}>
							<Form.Item
								label={t('Улчов бирлиги хакида батафсил маълумот')}
								name={'description'}
								rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
							>
								<TextArea />
							</Form.Item>
						</Col>

						<Col md={12} xs={24}>
							<div className='flex items-center justify-between space-x-5'>
								<Captcha onCaptchaChange={({ captchaGuid }) => setCaptchaGuid(captchaGuid)} />
								<div className='w-full relative'>
									<Form.Item
										rules={[{ required: true, message: t('Илтимос маълумотларни киритинг') }]}
										// label={t('captcha')}
										name={'captcha'}
										className='w-full mb-0'
									>
										<Input placeholder={t('Напишите символ из рисунка')} className='w-full mb-0' />
									</Form.Item>
								</div>
							</div>
						</Col>

						<Col md={24} xs={24}>
							<p>
								<i>
									{t(
										'Изоҳ.Янги ўлчов бирликларини қўшиш ёки ўзгартириш учун асослантирувчи хужжат мавжуд бўлса бириктиринг'
									)}
								</i>
								.
							</p>
							<Form.Item
								name='fileGuid'
								valuePropName='fileList'
								getValueFromEvent={normFile}
								noStyle
								// rules={[{ required: true, message: t("Пожалуйста загрузите фото") }]}
							>
								<Upload
									beforeUpload={beforeUpload}
									action={`${process.env.REACT_APP_API_URL}/file/upload`}
									headers={{
										Authorization: 'Bearer ' + localStorage.getItem('token'),
									}}
									accept='image/png, image/gif, image/jpeg'
									data={(file: UploadFile) => {
										return {
											content_type: file.type,
											file_size: file.size,
											original_filename: file.name,
										};
									}}
									listType='picture'
									multiple
								>
									<Button size={'large'} icon={<PlusIcon />}>
										{t('Загрузить фото')} <span style={{ fontSize: '10px', paddingLeft: '5px' }}>(10 mb)</span>
									</Button>
								</Upload>
							</Form.Item>
						</Col>

						<Col md={24} xs={24}>
							<div className={classes.buttons}>
								<Button onClick={() => setIsModalVisible(false)} type={'text'} size={'large'} shape={'round'}>
									{t('Отменить')}
								</Button>
								<Button
									type={'primary'}
									size={'large'}
									shape={'round'}
									htmlType={'submit'}
									loading={addNewProductMutations.isLoading}
								>
									{t('Отправить')}
								</Button>
							</div>
						</Col>
					</Row>
				</Form>
			)}
		</Modal>
	);
};

export default React.memo(AddNewProduct);
