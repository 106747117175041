import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { requestWithToken } from 'services/api';

import { favoritesDTO } from 'types/utils';

type QueryData = favoritesDTO;
type Props = {
	size?: string | number;
	page?: number;
	text?: string;
};
const useGetFavoritesQuery = ({ size, page, text }: Props = {}) => {
	const { i18n } = useTranslation();
	const query = useQuery(['/company-products/by-params', page, size, text], async () => {
		const response = await requestWithToken.get<QueryData>(`/company-products/by-params`, {
			params: { size, page, text, lang: i18n.language },
		});

		return response;
	});

	return query;
};

export default useGetFavoritesQuery;
