import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Key } from 'antd/es/table/interface';

import { requestWithToken } from 'services/api';

type MutationVariables = { mxik: string | Key[] };
type MutationError = AxiosError;
type MutationData = AxiosResponse;

const useRemoveFromFavoritesMutation = () => {
  const mutation = useMutation<MutationData, MutationError, MutationVariables>(({ mxik }) => {
    if (Array.isArray(mxik)) {
      return requestWithToken.delete(`/company-products/remove/list`, { params: { list: JSON.stringify(mxik) } });
    } else {
      if (mxik.length === 14) {
        return requestWithToken.delete(`/company-products/remove/from-brand/${mxik}`);
      } else {
        return requestWithToken.delete(`/company-products/remove/${mxik}`);
      }
    }
  });

  return mutation;
};

export default useRemoveFromFavoritesMutation;
