import React, { FC } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import classes from "./CategoryCard.module.scss";
import { Container } from "../../shared";
import { ReactComponent as ArrowChevron } from "assets/svg/right-chevron.svg";

type Props = {
	className?: string;
	icon: string;
	title: string;
	count?: string;
	active?: boolean;
};

const CategoryCard: FC<Props> = ({ className, active, icon, title, count, ...props }) => {
	const classNames = cn(classes["category-card"], active && classes.active, className && className);
	return (
		<>
			<div className={classNames}>
				<div className={classes["category-card_image"]} dangerouslySetInnerHTML={{ __html: icon }} />

				<p className={classes["category-card_title"]}>{title}</p>
				<span className={classes["category-card_count"]}>{count}</span>
			</div>
			{active && <div className={classes.gadot}></div>}
			{active && (
				<div className={classes["category-list"]}>
					<Container>
						<ul>
							<li>
								<ArrowChevron />
								<Link to={"#"}>tralala</Link>
							</li>
							<li>
								<ArrowChevron />
								<Link to={"#"}>tralala</Link>
							</li>
							<li>
								<ArrowChevron />
								<Link to={"#"}>tralala</Link>
							</li>
							<li>
								<ArrowChevron />
								<Link to={"#"}>tralala</Link>
							</li>
							<li>
								<ArrowChevron />
								<Link to={"#"}>tralala</Link>
							</li>
						</ul>
					</Container>
				</div>
			)}
		</>
	);
};

export default CategoryCard;
