import { Tooltip } from 'antd';
import get from 'lodash.get';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { request, requestWithToken } from 'services/api';

function Captcha({ onCaptchaChange }: { onCaptchaChange: ({ captchaGuid }: { captchaGuid: string }) => unknown }) {
	const { t } = useTranslation();

	const queryCaptcha = useQuery({
		queryKey: [`captcha/get`],
		queryFn: () => {
			return request.get(`/captcha/get`);
		},
	});

	const captchaImage = get(queryCaptcha, 'data.data.image', '');
	const captchaGuid = get(queryCaptcha, 'data.data.guid', '');

	useEffect(() => {
		onCaptchaChange({ captchaGuid });
	}, [captchaGuid]);

	return (
		<>
			<div className='flex items-center space-x-3'>
				<img
					className='!h-12 !rounded-md overflow-hidden'
					src={`data:image/png;base64, ${captchaImage}`}
					alt='captcha'
					style={{ border: '1px solid rgb(156, 163, 175, 0.5)' }}
				/>

				<Tooltip title={t('Обновить')}>
					<svg
						onClick={() => queryCaptcha.refetch()}
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='!h-6 flex-shrink-0 cursor-pointer hover:text-blue-default duration-[120ms]'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
						/>
					</svg>
				</Tooltip>
			</div>
		</>
	);
}

export default Captcha;
