import React, { FC } from "react";
import { Pagination, PaginationProps } from "antd";
import "./AntPagionation.scss";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

type Props = PaginationProps & {
	itemRender?: any;
};

const AntPagination: FC<Props> = ({ ...props }) => {
	const { t } = useTranslation();
	const tablet = useMediaQuery({ query: "(max-width: 780px)" });

	function itemRender(current: number, type: string, originalElement: any) {
		if (type === "prev") {
			return <span>{!tablet && t("Предыдущая")}</span>;
		}
		if (type === "next") {
			return <span>{!tablet && t("Следующая")}</span>;
		}
		return originalElement;
	}

	return <Pagination showLessItems={tablet} className={"ant-pagination"} showSizeChanger={false} itemRender={itemRender} {...props} />;
};

export default AntPagination;
