import React from 'react';

import classes from './Help.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';

type Props = {
  buttonLabel: string;
  description: string;
  handleButton?: () => void;
};

const Help = ({ buttonLabel, description, handleButton }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classes.help}>
      <p>{description}</p>
      <Button
        type={'primary'}
        shape={'round'}
        size={'large'}
        onClick={() => (handleButton ? handleButton() : window.location.replace('https://yordam.soliq.uz/'))}
      >
        {buttonLabel || t('Оставить заявку')}
      </Button>
    </div>
  );
};

export default Help;
