import { useTranslation } from 'react-i18next';
import classes from './Footer.module.scss';
import penIcon from 'assets/svg/footerIconpen.svg';
import questionIcon from 'assets/svg/footerIconQuestion.svg';
import AppStore from 'assets/svg/AppStore.svg';
import GooglePlay from 'assets/svg/GooglePlay.svg';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CallCentre from '../../../../assets/svg/callCentre.svg';
import { useHistory, useLocation } from 'react-router-dom';
import Container from 'components/shared/Container';

type iconDataProp = {
	icon: any;
	link: string;
	linkText: string;
	additional?: string;
};
const Footer = () => {
	const tablet = useMediaQuery({ query: '(max-width: 1024px)' });
	const mobile = useMediaQuery({ query: '(max-width:480px)' });
	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation();

	const iconData = [
		{
			icon: penIcon,
			link: 'https://e-imzo.uz/#how_to_get',
			linkText: t('Как получить ЭЦП?'),
			additional: t('Подробная инструкция'),
		},
		{
			icon: questionIcon,
			link: '/faq',
			linkText: t('Ответы на вопросы'),
		},
	];

	// const path = useMemo(() => location.pathname === "/cabinet" || location?.pathname === "/catalog", [location.pathname]);
	// comment
	const onHandlerChange = () => {
		if (location.pathname === '/cabinet') {
			if (location?.search == '?product') {
				history.replace(location.pathname);
			} else {
				history.replace(location.pathname + '?product');
			}
		}

		if (location.pathname === '/catalog') {
			if (location?.search === '?hidden=true') {
				history.replace(location.pathname);
			} else {
				history.replace(location.pathname + '?hidden=true');
			}
		}
	};

	return (
		<footer className={classes.footerWrapper}>
			{tablet ? (
				<Container extraClass={classes.containerForTablet}>
					<div>
						<div className={classes.iconWrapperForTablet}>
							{iconData?.map((d: iconDataProp, i: number) => {
								return (
									<div key={i} className={classes.card}>
										<img className={classes.icon} src={d.icon} alt='pen' />
										<div>
											<div className={classes.footerLink}>
												<a href={d.link}>{d.linkText}</a>
												<span>{d.additional}</span>
											</div>
										</div>
									</div>
								);
							})}
							<div className={classes.callCentre}>
								<img src={CallCentre} alt='callCentre' />
								<div>{t('Колл-центр')}:</div>
								{/* {path ? (
								) : (
									<h1>
										<a href="tel:1198">1198</a>
									</h1>
								)} */}
								<h1>
									11
									<div onClick={onHandlerChange}>9</div>8
								</h1>
							</div>
						</div>
					</div>
					{mobile ? null : (
						<div className={classes.mobileAppsForTablet}>
							<div>
								<p>{t('Приложения my.soliq.uz')}</p>
								<div>
									<a href='/'>
										<img src={AppStore} alt='AppStore' />
									</a>
									<a href='/'>
										<img src={GooglePlay} alt='GooglePlay' />
									</a>
								</div>
							</div>
						</div>
					)}
				</Container>
			) : (
				<Container extraClass={classes.container}>
					<div className={classes.iconWrapper}>
						{iconData?.map((d: iconDataProp, i: number) => {
							return (
								<div key={i} className={classes.card}>
									<img className={classes.icon} src={d.icon} alt='pen' />
									<div>
										<div className={classes.footerLink}>
											<a href={d.link}>{d.linkText}</a>
											<span>{d.additional}</span>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</Container>
			)}

			<div className={classes.outerFooter}>
				<Container extraClass={classes.containerExtra}>
					<div
						style={tablet ? { justifyContent: 'space-around' } : { justifyContent: 'space-between' }}
						className={classes.content}
					>
						<p>
							{t(
								'© 2021-2024 Налоговый комитет. Полное или частичное использование материалов возможно только с активной гиперссылкой..'
							)}
						</p>
						<p>
							{t('"Электронные налоговые услуги" портал электронных государственных услуг налоговых органов', {
								keySeparator: '|',
							})}
						</p>
						{tablet ? null : (
							<div className={classes.callCentre}>
								<img src={CallCentre} alt='callCentre' />
								<div>
									{t('Колл-центр')}
									<span onClick={onHandlerChange}>:</span>
								</div>

								<h1>1198</h1>
							</div>
						)}
					</div>
				</Container>
			</div>
		</footer>
	);
};

export default Footer;
