import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Dropdown } from "components";

const Language = () => {
	const { t, i18n } = useTranslation();
	const [lang, setLang] = useState();
	useEffect(() => {
		const curLang = localStorage.getItem("i18nextLng");
		switch (curLang) {
			case "ru":
				setLang(t("Русский"));
				break;
			// case "eng":
			// 	setLang(t("English"));
			// break;
			case "uz_cyrl":
				setLang(t("Ўзбек"));
				break;
			case "uz_latn":
				setLang(t("O'zbek"));
				break;
			default:
				setLang(t("Ўзбек"));
		}
	}, [t]);

	const changeLang = (e: string) => {
		i18n.changeLanguage(e);
		window.location.reload();
	};
	const langMenu = (
		<Menu>
			<Menu.Item key="uz_cyrl" onClick={() => changeLang("uz_cyrl")}>
				{t("Ўзбек")}
			</Menu.Item>
			<Menu.Item key="uz_latn" onClick={() => changeLang("uz_latn")}>
				{t("O'zbek")}
			</Menu.Item>
			<Menu.Item key="ru" onClick={() => changeLang("ru")}>
				{t("Русский")}
			</Menu.Item>
			{/*<Menu.Item key="ru" onClick={() => changeLang("eng")}>*/}
			{/*	{t("English")}*/}
			{/*</Menu.Item>*/}
		</Menu>
	);
	return (
		<div style={{ cursor: "pointer" }}>
			<Dropdown overlay={langMenu} selected={lang} trigger={["click"]} />
		</div>
	);
};

export default Language;
