import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Col, Row, Divider, message, Dropdown, Menu, Space } from 'antd';
import { useMediaQuery } from 'react-responsive';

import classes from './Header.module.scss';
import Language from '../components/Language';
import Container from '../../Container';
import Navbar from '../components/Navbar';
import DrawerMenu from '../../DrawerMenu';
import Call from 'assets/svg/call.svg';
import ArrowDown from 'assets/svg/arrowdown.svg';
import Logo from 'assets/svg/logo.svg';
import { useGetProfileQuery } from 'hooks/queries';
import { useQueryClient } from 'react-query';
import { useGeneralAPIMutation } from 'hooks/mutations';
import { Button } from 'components';
import { Button as AndButton, Tooltip } from 'antd';
import { ReactComponent as LogOutIcon } from 'assets/svg/log-out.svg';
import get from 'lodash.get';

const Header = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const queryClient = useQueryClient();

	const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);

	const tablet = useMediaQuery({ query: '(max-width: 780px)' });
	//queries
	const userQuery = useGetProfileQuery();
	//mutation hooks
	const logOutMutation = useGeneralAPIMutation('/user/logout', 'post');

	const handleLogOut = () => {
		const mutationPromise = logOutMutation.mutateAsync({});
		const hide = message.loading('Юкланмоқда...', 0);

		mutationPromise
			.then(() => {
				hide();
				localStorage.removeItem('token');
				queryClient.removeQueries('/user/current-user');
				setVisibleDrawer(false);
				history.push('/');

				window.location.reload();
			})
			.catch(() => {
				hide();
				message.error('Хатолик юз берди');
			});
	};

	useEffect(() => {
		setVisibleDrawer(false);
	}, [location.pathname, localStorage.getItem('token'), userQuery.isFetched]);

	const menuLinks = [
		{ id: 1, label: t('Главная'), link: '/' },
		{ id: 2, label: t('Новое на портале'), link: '/news' },
		{
			id: 3,
			label: t('Все услуги'),
			link: { pathname: 'https://soliq.uz/page/elektron-raqamli-imzo' },
			target: true,
		},

		{ id: 2, label: t('Запросы ИКПУ'), link: !userQuery.data?.data ? '/sign-in' : '/activities' },
		{ id: 1, label: t('Избранные ИКПУ'), link: !userQuery.data?.data ? '/sign-in' : '/cabinet' },
	];

	const privateMenuLinks = [
		{ id: 2, label: t('Запросы ИКПУ'), link: '/activities' },
		{ id: 1, label: t('Избранные ИКПУ'), link: '/cabinet' },
	];

	return (
		<Fragment>
			<Container>
				{/*======Menu=======*/}
				<DrawerMenu visible={visibleDrawer} onClose={() => setVisibleDrawer(false)}>
					<h3 className={classes['menu-title']}>{t('Меню')}</h3>
					{menuLinks.map((link) => (
						<Link target={link.target ? '_blank' : undefined} className={classes['menu-item']} to={link.link} key={link.id}>
							{link.label}
						</Link>
					))}
					<Divider dashed style={{ borderColor: '#B6BCCD' }} />
					<h3 className={classes['menu-title']}>{t('Персональные кабинеты')}</h3>
					{userQuery.data?.data && tablet && (
						<div className={classes.avatarPart}>
							<h6>{userQuery.data?.data.shortName}</h6>
							<p>
								{get(userQuery, 'data.data.orgType', 1) !== 3 ? (
									<>
										<span className={'mr-5'}>{t('ПИНФЛ')}</span>
										<span>{get(userQuery, 'data.data.pinfl')}</span>
									</>
								) : (
									<>
										<span className={'mr-5'}>{t('ИНН')}</span>
										<span>{get(userQuery, 'data.data.tin')}</span>
									</>
								)}
							</p>
						</div>
					)}
					{!userQuery.data?.data && (
						<Link className={classes['menu-item']} to={'/sign-in'}>
							{t('Авторизация')}
						</Link>
					)}
					{userQuery.data?.data &&
						privateMenuLinks.map((item) => (
							<Link className={classes['menu-item']} key={item.id} to={item.link}>
								{item.label}
							</Link>
						))}
					{userQuery.data?.data && (
						<Link className={classes['menu-item']} to={'#'} onClick={() => handleLogOut()}>
							{t('Выйти')}
						</Link>
					)}
				</DrawerMenu>
				{/*======Menu=======*/}
				<div className={classes.header}>
					<Row align='middle'>
						<Col className={classes.logoPart} span={userQuery.data?.data ? (!tablet ? 13 : 20) : tablet ? 20 : 12}>
							<div className={classes.logoTitle}>
								<Link to='/'>
									<img className={classes.logo} src={Logo} alt='logo' />
								</Link>
								{/* <img className={classes.logo} src={Logo} alt="logo" /> */}
								<Link to='/'>{t('ЕДИНЫЙ  ЭЛЕКТРОННЫЙ  НАЦИОНАЛЬНЫЙ КАТАЛОГ ПРОДУКЦИИ (ТОВАРОВ И УСЛУГ)')}</Link>
							</div>
							{!tablet && (
								<Space size={20}>
									<div className={classes.call}>
										<span>
											<img src={Call} alt='call' />
											<p>
												<a href='tel:1198' style={{ color: 'unset' }}>
													1198
												</a>
											</p>
										</span>
										<p>{t('Колл–центр')}</p>
									</div>
								</Space>
							)}
							{!tablet && <div className={classes.elementTop} />}
						</Col>
						{!userQuery.data?.data
							? null
							: !tablet && (
									<Col className={classes.avatarPart} span={userQuery.data?.data ? 7 : 12}>
										{/*<Avatar src={<Image preview={false} src={AvatarPhoto} />} />*/}
										<div>
											<h6>{userQuery.data?.data.shortName}</h6>
											<p>
												{get(userQuery, 'data.data.orgType', 1) !== 3 ? (
													<>
														<span className={'mr-5'}>{t('ПИНФЛ')}</span>
														<span>{get(userQuery, 'data.data.pinfl')}</span>
													</>
												) : (
													<>
														<span className={'mr-5'}>{t('ИНН')}</span>
														<span>{get(userQuery, 'data.data.tin')}</span>
													</>
												)}
											</p>
										</div>
									</Col>
							  )}
						<Col className={classes.langPart} span={tablet ? 4 : userQuery.data?.data ? 4 : 12}>
							{!tablet && <Language />}
							{!userQuery.data?.data && !tablet && (
								<Button size={'large'} shape={'round'} type={'primary'}>
									<Link to={'/sign-in'}>{t('Войти в личный кабинет')}</Link>
								</Button>
							)}
							<div style={tablet ? { marginLeft: 'auto' } : { marginLeft: '0' }}>
								{userQuery.data?.data && (
									<Tooltip title={t('Выйти')}>
										<AndButton onClick={() => handleLogOut()} className={classes.button_log} icon={<LogOutIcon />} />
									</Tooltip>
								)}
							</div>
							{!userQuery.data?.data ? null : <div className={classes.elementTop2} />}
						</Col>
					</Row>
				</div>
			</Container>
			{tablet ? (
				<div className={classes.tabletNavbarWrapper}>
					<Container extraClass={classes.tabletNavbarContainer}>
						<Dropdown
							overlay={
								<Menu>
									{menuLinks.map((link) => (
										<Menu.Item key={link.id}>
											<Link
												target={link.target ? '_blank' : undefined}
												className={classes['menu-item']}
												to={link.link}
												key={link.id}
											>
												{link.label}
											</Link>
										</Menu.Item>
									))}
								</Menu>
							}
							trigger={['click']}
						>
							<a className={classes.allServices} onClick={(e) => e.preventDefault()}>
								{t('Все услуги и сервисы')} {<img src={ArrowDown} alt='arrow' />}
							</a>
						</Dropdown>
						<Language />
					</Container>
					<div className={classes.tabletMenu}>
						<Container>
							<div className={classes.menuContent} />
						</Container>
					</div>
				</div>
			) : (
				<Navbar />
			)}
		</Fragment>
	);
};

export default Header;
