import i18n from "services/i18n";

const currLang = i18n.language;

export const switchType = (type: string) => {
	switch (type) {
		case "1":
			return currLang === "ru" ? "Потребительская" : currLang === "uz_latn" ? "Iste'mol qadog'i" : "Истеъмол қадоғи";
		case "2":
			return currLang === "ru" ? "Внешняя" : currLang === "uz_latn" ? "Tashqi qadoq" : "Ташқи қадоқ";
		case "3":
			return currLang === "ru" ? "Транспортная" : currLang === "uz_latn" ? "Transport qadog'i" : "Транспорт қадоқғи";

		default:
			return "";
	}
};
