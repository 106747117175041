import { useQuery } from "react-query";

import { requestWithToken } from "services/api";
import { productsDTO } from "types/utils";

type QueryData = productsDTO;
type Props = {
	size?: string;
	page?: number;
	text?: string;
};
const useGetFavoritesQuery = ({ size, page, text }: Props = {}) => {
	const query = useQuery("/user-products/list", async () => {
		const response = await requestWithToken.get<QueryData>(`/user-products/list`, { params: { size, page, text } });

		return response;
	});

	return query;
};

export default useGetFavoritesQuery;
