import React, { FC } from "react";
import { Input } from "antd";
import { TextAreaProps } from "antd/es/input";
import cn from "classnames";

type Props = TextAreaProps & {
	label?: string;
	inputType?: "default" | "primary" | undefined;
	isSuccess?: boolean;
	size?: "small" | "middle" | "large" | undefined;
};

const TextArea: FC<Props> = ({ label, isSuccess, size, inputType = "default", ...props }) => {
	const classNames = cn("input", inputType === "default" && "default", isSuccess && "is-success", size && size);
	return (
		<div className={classNames}>
			{label && <span className={"input-label"}>{label}</span>}
			<Input.TextArea {...props} />
		</div>
	);
};

export default TextArea;
