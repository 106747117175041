import { useQuery } from 'react-query';
import { NewsDTO } from 'types/utils';
import { requestMain } from 'services/api';
import { useTranslation } from 'react-i18next';

type QueryData = NewsDTO;
type Props = {
	size?: number;
	page?: number;
};

const useGetNewsQuery = ({ size, page }: Props) => {
	const { i18n } = useTranslation();

	const query = useQuery(
		'/news',
		async () => {
			const response = await requestMain.get<QueryData>(`/news/list`, {
				params: { size, page, sort: '', lang: i18n.language },
			});
			return response;
		},
		{ enabled: true }
	);

	return query;
};

export default useGetNewsQuery;
