import React, { FC, useMemo } from 'react';
import { AutoComplete, Col, message, Radio, RadioChangeEvent, Row, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as StarIcon } from 'assets/svg/simplIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/infoIcon.svg';
import classes from './Search.module.scss';
import { Input } from 'components/shared';
import { searchDTO } from 'types/utils';
import { useTranslation } from 'react-i18next';
import plusIcon from '../../assets/svg/service-icon/plus.svg';
import { Cards } from '../index';
import { SearchLabel } from './components';
import { formatTimeDate2 } from '../../libs/timeConverter';
import { copyOnClick } from '../../libs/copyOnClick';
import SearchCard from '../SearchCard';
import { useMediaQuery } from 'react-responsive';
import { Search as SvgSearch } from 'lucide-react';
import { useDeepSearch } from 'pages/SearchDeep';
import get from 'lodash.get';
import useDebounceHook from 'libs/useDebounceHook';

type Props = {
	searchData?: searchDTO;
	products?: any;
	searchValue?: string;
	searchValueChange?: (value: string) => void;
	searchLoading?: boolean;
	withFilter?: boolean;
	radioValue?: string;
	radioValueChange?: (value: string) => void;
	setLoading?: (value: boolean) => void;
	onPopupScroll?: (value: any) => void;
	setKeyPress?: (value: any) => void;
	searchLabel?: string;
	onNotFound?: () => void;
	activeTab: 'right' | 'wrong';
	setActiveTab: (value: 'right' | 'wrong') => void;
	rightLength: number;
	wrongLength: number;
	isHistory?: boolean;
	historyData?: any;
};

const Search: FC<Props> = ({
	searchValue,
	searchValueChange,
	searchData,
	withFilter,
	searchLoading,
	radioValue,
	radioValueChange,
	setLoading,
	onPopupScroll,
	searchLabel,
	onNotFound,
	activeTab,
	setActiveTab,
	rightLength,
	wrongLength,
	isHistory,
	historyData,
	products,
	setKeyPress,
}) => {
	const history = useHistory();
	const { t } = useTranslation();
	const mobile = useMediaQuery({ query: '(max-width: 375px)' });
	const debouncedSearchValue = useDebounceHook(searchValue, 500);

	const queryProducts = useDeepSearch({
		queryKey: { search: debouncedSearchValue, size: 20, page: 0 },
	});

	const deepSearchTotal = get(queryProducts, 'data.data.recordTotal', 0) as number;
	const options = useMemo(() => {
		return searchValue !== '' && (products ? products.length : searchData?.data?.content?.length)
			? [
					{
						label: (
							<SearchLabel
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								rightLength={rightLength}
								wrongLength={wrongLength}
							>
								<Spin size='small' spinning={queryProducts.isLoading}>
									<Link to={`/search-deep?search=${searchValue}`} className='relative flex'>
										<button className='relative py-1.5 !pr-5 !pl-5 rounded-lg flex items-center gap-3 cursor-pointer duration-200 active:scale-95 bg-green-500 hover:bg-green-600 text-white'>
											<SvgSearch strokeWidth={1.5} size={15} /> {t('Возможно вы искали')} - {`${deepSearchTotal}`}
										</button>
									</Link>
								</Spin>
							</SearchLabel>
						),
						options:
							(products ? products : searchData?.data?.content).map((item: any, index: any) => {
								const { mxikCode } = item;

								return {
									key: item.mxikCode,
									label: <SearchCard key={item.mxikCode} searchValue={searchValue} item={item} />,
									value: (searchLabel = `${mxikCode}`),
								};
							}) || [],
					},
			  ]
			: [];
	}, [products, searchData?.data, searchValue]);

	const onSelect = (value: string, option: any) => {
		history.push(`/attribute/${option.key}`);
	};

	const onChange = (data: string) => {
		if (radioValue == '2' && data.length != 0) {
			const isnum = /^\d+$/.test(data);
			const isValidLength = data.length < 18;

			isValidLength && isnum && searchValueChange && searchValueChange(data);
		} else {
			setLoading && setLoading(true);
			if (withFilter) {
				radioValue === '1' || radioValue === '5' || radioValue === '6' || radioValue === '7' || radioValue === '2'
					? searchValueChange && searchValueChange(data)
					: searchValueChange && searchValueChange(data?.replace(/[^\d]+/g, ''));
			} else {
				searchValueChange && searchValueChange(data);
			}
		}
	};

	const radioOnChange = (e: RadioChangeEvent) => {
		searchValueChange && searchValueChange('');
		radioValueChange && radioValueChange(e.target.value);
	};

	const curLang = localStorage.getItem('i18nextLng');
	const copyOnClickHandler = (text: string) => {
		copyOnClick(text);
		message.success(t('скопировано'));
	};

	const massageMemo = useMemo(() => {
		switch (curLang) {
			case 'ru':
				return (
					<div className={classes.infoText}>
						<b>Примечание</b>
						<p>
							С&nbsp;
							{historyData?.updatedAt && formatTimeDate2(historyData?.updatedAt)}
							&nbsp;года&nbsp;ИКПУ-код данного товара (услуги) изменен на&nbsp;
							<b onClick={() => copyOnClickHandler(historyData?.mxikCode)}>{historyData?.mxikCode}</b>
						</p>
					</div>
				);
				break;
			case 'uz_latn':
				return (
					<div className={classes.infoText}>
						<b>Eslatma</b>
						<p>
							&nbsp;
							{historyData?.updatedAt && formatTimeDate2(historyData?.updatedAt)}
							&nbsp;yil dan boshlab ushbu mahsulot (xizmat) ning MXIK kodi &nbsp;
							<b onClick={() => copyOnClickHandler(historyData?.mxikCode)}>{historyData?.mxikCode}</b>&nbsp;o‘zgartirildi
						</p>
					</div>
				);
				break;
			case 'uz_cyrl':
				return (
					<div className={classes.infoText}>
						<b>Эслатма</b>
						<p>
							&nbsp;
							{historyData?.updatedAt && formatTimeDate2(historyData?.updatedAt)}&nbsp;йил дан бошлаб ушбу маҳсулот (хизмат)
							нинг МХИК коди &nbsp;
							<b onClick={() => copyOnClickHandler(historyData?.mxikCode)}>{historyData?.mxikCode}</b>&nbsp;ўзгартирилди
						</p>
					</div>
				);
				break;
			default:
				return (
					<div className={classes.infoText}>
						<b>{t('Примечание')}</b>
						<p>
							С&nbsp;
							{historyData?.updatedAt && formatTimeDate2(historyData?.updatedAt)}
							&nbsp;года&nbsp;ИКПУ-код данного товара (услуги) изменен на&nbsp;
							<b onClick={() => copyOnClickHandler(historyData?.mxikCode)}>{historyData?.mxikCode}</b>
						</p>
					</div>
				);
		}
	}, [curLang, historyData]);

	const notFoundContent = () => {
		if (searchValue !== '') {
			if (searchLoading) {
				return (
					<div className={classes['search-empty']}>
						<Spin />
					</div>
				);
			}

			return (
				options?.length === 0 && (
					<>
						<SearchLabel
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							rightLength={rightLength}
							wrongLength={wrongLength}
						>
							<Spin size='small' spinning={queryProducts.isLoading}>
								<Link to={`/search-deep?search=${searchValue}`} className='relative flex'>
									<button className='relative py-1.5 !pr-5 !pl-5 rounded-lg flex items-center gap-3 cursor-pointer duration-200 active:scale-95 bg-green-500 hover:bg-green-600 text-white'>
										<SvgSearch strokeWidth={1.5} size={15} /> {t('Возможно вы искали')} - {`${deepSearchTotal}`}
									</button>
								</Link>
							</Spin>
						</SearchLabel>

						{isHistory && radioValue === '2' && historyData ? (
							<>
								{historyData?.mxikCode ? (
									<div className={classes.massage}>
										<div className={classes.textInfo}>
											<div className={classes.icon_wrapper}>
												<InfoIcon />
											</div>
											{massageMemo}
										</div>
									</div>
								) : (
									<Cards.InfoCard
										empty
										icon={plusIcon}
										text={t('Введите название товара правильно либо отправьте запрос на добавление')}
										path={'#'}
										direction={'vertical'}
										onClick={() => onNotFound && onNotFound()}
									/>
								)}
							</>
						) : (
							<Cards.InfoCard
								empty
								icon={plusIcon}
								text={t('Введите название товара правильно либо отправьте запрос на добавление')}
								path={'#'}
								direction={'vertical'}
								onClick={() => onNotFound && onNotFound()}
							/>
						)}
					</>
				)
			);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent): any => {
		if (event.code === 'Enter' && setKeyPress) {
			setKeyPress(true);
			setTimeout(() => {
				setKeyPress(false);
			}, 0);
		}
	};

	return (
		<div className={classes.search}>
			<AutoComplete
				// @ts-ignore
				onKeyDown={handleKeyPress}
				className={classes['search-input']}
				options={options || []}
				onSelect={onSelect}
				filterOption={false}
				onChange={onChange}
				onPopupScroll={(event) => onPopupScroll && onPopupScroll(event)}
				notFoundContent={notFoundContent()}
				value={searchValue}
				listHeight={600}
				dropdownClassName={'!h-[600px]'}
			>
				<Input
					placeholder={`...`}
					label={`${searchLabel ? searchLabel : t('Поиск')}`}
					searchType={'secondary'}
					loading={searchLoading}
				/>
			</AutoComplete>
			{withFilter && (
				<Radio.Group onChange={radioOnChange} value={radioValue} className={classes.radio}>
					<Row style={{ width: '100%' }}>
						<Col lg={4} md={6} xs={24} style={{ display: 'flex' }}>
							<Radio value={'5'}>{t('Расширенный поиск по тексту')}</Radio>
						</Col>
						<Col lg={4} md={6} xs={24} style={{ display: 'flex' }}>
							<Radio value={'1'}>{t('Поиск по тексту')}</Radio>
						</Col>
						<Col lg={4} md={6} xs={24} style={{ display: 'flex' }}>
							<Radio value={'2'}>{t('Поиск по ИКПУ')}</Radio>
						</Col>
						<Col lg={4} md={6} xs={24} style={{ display: 'flex' }}>
							<Radio value={'3'}>{t('Поиск по штрих-коду')}</Radio>
						</Col>
						<Col lg={4} md={6} xs={24} style={{ display: 'flex' }}>
							<Radio value={'4'}>{t('Поиск по кодам ТН ВЭД')}</Radio>
						</Col>
						<Col lg={4} md={6} xs={24} style={{ display: 'flex' }}>
							<Radio value={'6'}>{t('Поиск по бренду')}</Radio>
						</Col>
						<Col lg={6} md={8} xs={24} style={{ display: 'flex' }}>
							<Radio value={'7'}>{t('Поиск по номеру рег. удостоверения (для лекарственных средств)')}</Radio>
						</Col>
					</Row>
				</Radio.Group>
			)}
			{withFilter && (
				<div className={classes.massage}>
					<div className={classes.text}>
						{t('Позиции отмеченные')}
						{!mobile && <StarIcon style={{ minWidth: '26px' }} />}
						{t(
							'являются корневыми кодами и могут быть использованы в случае отсутствия в Каталоге идентичных товаров с учетом атрибутов'
						)}
					</div>
				</div>
			)}
		</div>
	);
};

function propsAreEqual(prevProps: Props, nextProps: Props) {
	return (
		prevProps.searchData === nextProps.searchData &&
		prevProps.searchValue === nextProps.searchValue &&
		prevProps.searchLoading === nextProps.searchLoading &&
		prevProps.withFilter === nextProps.withFilter &&
		prevProps.radioValue === nextProps.radioValue &&
		prevProps.searchLabel === nextProps.searchLabel &&
		prevProps.activeTab === nextProps.activeTab &&
		prevProps.rightLength === nextProps.rightLength &&
		prevProps.wrongLength === nextProps.wrongLength &&
		prevProps.isHistory === nextProps.isHistory &&
		prevProps.historyData === nextProps.historyData
	);
}
export default React.memo(Search, propsAreEqual);
