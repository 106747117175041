import { Alert, Spin, Tooltip } from 'antd';
import classNames from 'classnames';
import { Container, Pagination } from 'components/shared';
import useDebounce from 'libs/useDebounceHook';
import { trimEnd } from 'lodash';
import get from 'lodash.get';
import { ArrowRight, Search, Star } from 'lucide-react';
import { TSearchDeep } from 'pages/SearchDeep/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { For } from 'react-utilify';
import { request } from 'services/api';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import ZoomImageWithAlternate from 'pages/SearchDeep/ZoomImageWithAlternate';
import NewSelectGroupCode from 'pages/SearchDeep/NewSelectGroupCode';
import { useState } from 'react';
import NewSelectCategoryCode from 'pages/SearchDeep/NewSelectCategoryCode';

const SIZE = 20;

export function useDeepSearch({ queryKey }: { queryKey: Record<string, unknown> }) {
	const { i18n } = useTranslation();

	return useQuery({
		queryKey: ['queryProductsbyElasticSearch', queryKey],
		queryFn: () => {
			return request({
				method: 'GET',
				url: '/elasticsearch/search',
				params: {
					lang: i18n.language,
					...queryKey,
				},
			});
		},
		retry: 1,
	});
}

function SearchDeep() {
	const { t } = useTranslation();
	const history = useHistory();
	const [search, setSearch] = useQueryParam('search', StringParam);
	const debouncedSearch = useDebounce(search, 500);
	const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
	const [filter, setFilter] = useState({
		categoryCode: '',
		groupCode: '',
	});
	const queryProducts = useDeepSearch({
		queryKey: {
			search: debouncedSearch,
			page: page - 1,
			size: SIZE,
			group_code: filter.groupCode ? filter.groupCode : null,
			category_code: filter.categoryCode ? filter.categoryCode : null,
		},
	});

	const stringReplace = (str: string) => {
		return reactStringReplace(str, debouncedSearch || '', (match) => (
			<b className='bg-lime-300 text-gray-900 border-b border-dashed rounded-t-sm border-gray-700 font-normal'>{match}</b>
		));
	};

	const searchResults = get(queryProducts, 'data.data.data', []) as TSearchDeep[];
	const deepSearchTotal = get(queryProducts, 'data.data.recordTotal', 0) as number;

	return (
		<>
			<Container>
				<div className='!h-[30px]' />

				<div className='search bg-white px-7 py-7 shadow-md rounded-lg'>
					<label className='!relative' htmlFor='search'>
						<input
							id='search'
							name='search'
							type='text'
							value={search || ''}
							onChange={(e) => {
								if (page !== 1) {
									setPage(1);
								}

								setSearch(e.currentTarget.value);
							}}
							placeholder={t('Найти ...')}
							className='border-def !border-gray-300 placeholder:text-gray-300 focus:!border-gray-500 duration-200 w-full py-2 px-2 border-none outline-none focus:outline-none rounded-lg'
						/>

						<Search className='absolute right-3 top-0 pointer-events-none text-gray-400' size={17} />
					</label>

					<div className='h-[20px]' />

					<div className='flex items-center justify-between !gap-5'>
						<NewSelectCategoryCode
							onChange={(e, selectedOption) => setFilter({ ...filter, categoryCode: get(selectedOption, 'value', '') })}
						/>

						<NewSelectGroupCode
							onChange={(e, selectedOption) => setFilter({ ...filter, groupCode: get(selectedOption, 'value', '') })}
						/>
					</div>
				</div>

				<div className='!h-[30px]' />

				{/* Conditions for different states: loading, success, and empty */}
				{(() => {
					if (queryProducts.status === 'loading') {
						return (
							<div className='flex items-center justify-center py-5'>
								<Spin spinning={true} size='large' />
							</div>
						);
					}

					if (queryProducts.status === 'success') {
						if (searchResults.length > 0) {
							return (
								<div className='search bg-white px-7 py-5 rounded-lg'>
									<h3 className='text-2xl font-bold'>{t('Результат поиска')}</h3>

									<div className='h-[10px]' />

									<h3 className='text-lg font-medium'>
										{t('Найдено')} - {deepSearchTotal}
									</h3>

									<For each={searchResults}>
										{(result, idx) => {
											const isBlue = result.attributeName ? true : false;

											const list = [
												{
													title: t('Группа'),
													desc: result.groupName,
												},
												{
													title: t('Класс'),
													desc: result.className,
												},
												{
													title: t('Позиция'),
													desc: result.positionName,
												},
												{
													title: t('Субпозиция'),
													desc: result.subPositionName,
												},
											];

											if (isBlue) {
												list.push(
													{
														title: t('Бренд'),
														desc: result.brandName,
													},
													{
														title: t('Атрибут'),
														desc: result.attributeName,
													}
												);
											}

											const addCommaOnTruthy = <T,>(str: T[]) => {
												let attributeName = ``;

												str.forEach((s, idx) => {
													if (s) {
														attributeName += `${s}, `;
													}
												});

												attributeName = trimEnd(attributeName, ', ');

												if (!attributeName) {
													return null;
												}

												return attributeName;
											};

											return (
												<>
													<hr className='h-[1px] bg-gray-300 !my-6' />

													<div className='grid grid-cols-12 gap-5'>
														<div className='col-span-9'>
															<div className='bg-[#F6F6F7] rounded-lg py-2 px-2 grid grid-cols-2'>
																<p className='text-sm mb-0 py-1 px-2'>
																	{t('Код ИКПУ')}: <span className='font-bold ml-1'>{result.mxikCode}</span>
																</p>

																<p
																	className={classNames('text-sm mb-0 bg-[#E5F9CF] px-2 py-1 rounded-md', {
																		'!bg-blue-100': isBlue,
																	})}
																>
																	{t('Название ИКПУ')}:
																	<span className='font-bold ml-1 underline'>{stringReplace(result.name)}</span>
																</p>
															</div>

															<div className='!h-[10px]' />

															<div className='bg-[#F6F6F7] rounded-lg py-2 px-2 grid grid-cols-2'>
																<p className='text-sm mb-0 py-1 px-2'>
																	{t('Группа')}: <span className='font-bold ml-1'>{stringReplace(result.groupName)}</span>
																</p>

																<p className='text-sm mb-0 px-2 py-1'>
																	{t('Единица измерения')}:
																	<span className='font-bold ml-1'>
																		{addCommaOnTruthy([
																			result.unitsName,
																			result.packageName,
																			result.recommendedUnitsName,
																			result.recommendedCategoryUnitName,
																			result.categoryUnitName,
																		]) ?? '-'}
																	</span>
																</p>
															</div>

															<div className='!h-[10px]' />

															<div className='gap-5'>
																<div className='col-span-9 w-full'>
																	<div
																		className={classNames(
																			'w-full bg-[#E5F9CF] !px-5 !pt-3 !pb-1 rounded-lg flex justify-between',
																			{
																				'!bg-blue-100': isBlue,
																			}
																		)}
																	>
																		<div>
																			<For each={list}>
																				{(item, idx) => (
																					<div
																						className={classNames('flex items-center space-x-2')}
																						style={{ marginLeft: `${idx * 1}rem` }}
																					>
																						<svg
																							className='h-4 flex-shrink-0 !mb-4'
																							viewBox='0 0 20 21'
																							fill='none'
																							xmlns='http://www.w3.org/2000/svg'
																						>
																							<line
																								x1='0.5'
																								y1='2.18556e-08'
																								x2='0.499999'
																								y2='20'
																								stroke='black'
																								strokeWidth={2}
																							></line>
																							<line
																								x1='20'
																								y1='20.5'
																								x2='-4.37114e-08'
																								y2='20.5'
																								stroke='black'
																								strokeWidth={2}
																							></line>
																						</svg>

																						<p className='mb-0 font-bold text-black'>{stringReplace(item.title)}:</p>
																						<p className='mb-0 text-[#008810]'>{stringReplace(item.desc)}</p>
																					</div>
																				)}
																			</For>
																		</div>

																		{!isBlue ? (
																			<div className='bg-white flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center'>
																				<Star className='fill-[#008810] text-[#008810]' />
																			</div>
																		) : null}
																	</div>

																	<div className='!h-[10px]' />

																	<div className='flex items-center justify-between'>
																		<div>
																			<div className='flex items-center gap-10'>
																				<p className='text-sm mb-0'>
																					{t('Подлежит маркировке')}:
																					<span className='font-bold ml-2'>{result.label === '1' ? t('Да') : t('Нет')}</span>
																				</p>
																				<p className='text-sm mb-0'>
																					{t('Льгота')}:
																					<span className='font-bold ml-2'>{result.lgotaId ? t('Да') : t('Нет')}</span>
																				</p>

																				<p className='text-sm mb-0'>
																					{t('Штрих код')}:
																					<span className='font-bold ml-2'>{result.internationalCode ?? '-'}</span>
																				</p>
																			</div>

																			<p className='text-sm mb-0 flex items-center'>
																				<span className='!mr-2 flex-shrink-0'>{t('Описание товара')}:</span>

																				<Tooltip
																					placement='top'
																					overlayInnerStyle={{ width: '1000px' }}
																					title={result.property}
																				>
																					<p className='w-[35rem] m-0 truncate'>{result.property || '-'}</p>
																				</Tooltip>
																			</p>
																			<p className='text-sm mb-0 flex items-center'>
																				<span className='!mr-2 flex-shrink-0'>{t('MNN')}:</span>

																				<Tooltip
																					placement='top'
																					overlayInnerStyle={{ width: '1000px' }}
																					title={result.mnnName}
																				>
																					<p className='w-[35rem] m-0 truncate'>{result.mnnName || '-'}</p>
																				</Tooltip>
																			</p>
																		</div>

																		<button
																			onClick={() => {
																				history.push(`/attribute/${result.mxikCode}`);
																			}}
																			className='py-2 !pl-6 !pr-4 rounded-full flex items-center gap-3 cursor-pointer duration-200 active:scale-95 bg-gray-100 hover:bg-gray-200'
																		>
																			{t('Посмотреть')} <ArrowRight strokeWidth={1.5} size={15} />
																		</button>
																	</div>
																</div>
															</div>
														</div>

														<div
															className='col-span-3 px-3 rounded-lg !border !border-gray-300 !h-[270px] flex items-center justify-center'
															style={{ border: '1px solid' }}
														>
															<ZoomImageWithAlternate
																idx={idx}
																isService={Boolean(result.mxikCode.slice(0, 3).length > 98)}
																mxikCode={result.mxikCode}
															/>
														</div>
													</div>
												</>
											);
										}}
									</For>

									<div className='!h-[30px]' />

									<Pagination total={deepSearchTotal} pageSize={SIZE} current={page} onChange={(page) => setPage(page)} />
								</div>
							);
						}

						return <Alert message={t(`Ma'lumot topilmadi!`)} type='info' showIcon />;
					}

					if (queryProducts.status === 'error') {
						return <Alert message={t('Xatolik')} type='error' showIcon />;
					}
				})()}

				<div className='!h-[30px]' />
			</Container>
		</>
	);
}

export default SearchDeep;
