import React, { FC } from "react";
import { MaskedInput as AntMaskedInput } from "antd-mask-input";
import cn from "classnames";
import { MaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput";

type Props = {
	mask: string;
	label?: string;
	inputType?: "default" | "primary" | undefined;
	isSuccess?: boolean;
	size?: "small" | "middle" | "large" | undefined;
};

const MaskedInput: FC<Props & MaskedInputProps> = ({ mask, label, isSuccess, size, inputType = "default", ...props }) => {
	const classNames = cn("input", inputType === "default" && "default", isSuccess && "is-success", size && size);
	return (
		<div className={classNames}>
			{label && <span className={"input-label"}>{label}</span>}
			<AntMaskedInput mask={mask} {...props} />
		</div>
	);
};

export default MaskedInput;
