import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";

import { requestWithToken } from "services/api";

type MutationVariables = { type: string };
type MutationError = AxiosError;
type MutationData = AxiosResponse;

const useFavoriteFileDownloadMutation = () => {
	const mutation = useMutation<MutationData, MutationError, MutationVariables>(({ type }) =>
		requestWithToken.get(`/company-products/download/${type}`, { responseType: "arraybuffer" })
	);

	return mutation;
};

export default useFavoriteFileDownloadMutation;
