import { useQuery } from "react-query";

import { request } from "services/api";
import { shortDTO } from "types/utils";

type QueryData = shortDTO;
type Props = {
	code?: string;
	size?: number;
	page?: number;
	text?: string;
	download?: boolean;
	enabled?: boolean;
	lang?: string
};
const useGetSubPositionQuery = ({ code, size, page, text, download, lang, enabled = false }: Props) => {
	const query = useQuery(
		["/subposition/short-info", size, page, text, download],
		async () => {
			const response = await request.get<QueryData>(`/subposition/short-info`, {
				params: { positionCode: code, size, page, text, download, lang }
			});

			return response;
		},
		{ enabled, keepPreviousData: true }
	);

	return query;
};

export default useGetSubPositionQuery;
