import React, { FC, useState } from "react";
import classes from "./package.module.scss";
import { Divider, Space } from "antd";
import { useTranslation } from "react-i18next";
import { switchType } from "libs/switchType";

type Props = {
	index: number;
	unit: string;
	rootValue: string;
	packageName: string;
	packageUnit: string;
	containerName: string;
	type: string;
	childPackCode: number;
	parentCode: number;
	interNationalCode: string;
};

const Package: FC<Props> = ({
	index,
	unit,
	parentCode,
	interNationalCode,
	rootValue,
	childPackCode,
	packageUnit,
	packageName,
	type,
	containerName
}) => {
	const { t } = useTranslation();
	const unitTree = unit.split("&");

	const [isActive, setIsActive] = useState(false);

	return (
		<div className={classes.package}>
			<div className={`${classes.star} ${isActive && classes.active}`} onClick={() => setIsActive(!isActive)}>
				✩
			</div>

			<div className={classes.name}>
				<span className={"mr-5"}>{index}.</span>{" "}
				<h4 className={"mr-20"}>{!interNationalCode && !parentCode ? t("Единица измерение") : t("Сформированная упаковка")}:</h4>
				<ul className={classes.tree}>
					{unitTree.map((item, index) => (
						<li key={item} className={`ml-${(index + 1) * 20}`}>
							<span>
								{containerName && containerName[0].toUpperCase() + containerName.slice(1)} {containerName && "="} {item}
							</span>
						</li>
					))}
				</ul>
			</div>
			<div className={classes["package_info"]}>
				<Space size={20}>
					<div className={classes["package_info_item"]}>
						<h5 className={"mr-10"}>{t("Объём")}:</h5>
						<span>{rootValue}</span>
					</div>
					<div className={classes["package_info_item"]}>
						<h5 className={"mr-10"}>{t("Код упаковки")}:</h5>
						<span>{childPackCode}</span>
					</div>
					{packageName && (
						<div className={classes["package_info_item"]}>
							<h5 className={"mr-10"}>{t("Форма упаковки")}:</h5>
							<span>{packageName}</span>
						</div>
					)}
				</Space>
				<Divider dashed style={{ borderColor: "#B6BCCD" }} />
				<Space size={60}>
					<div className={classes["package_info_item"]}>
						<h5 className={"mr-20"}>{t("Единица измерение")}:</h5>
						<span>{packageUnit}</span>
					</div>
					<div className={classes["package_info_item"]}>
						<h5 className={"mr-20"}>{t("Вид упаковки")}:</h5>
						<span>{t(switchType(type))}</span>
					</div>
				</Space>
			</div>
		</div>
	);
};

export default Package;
