import { Suspense, useEffect } from 'react';
import { Layout, LazyLoad } from 'components/shared';
import { Route, Switch } from 'react-router-dom';

import routes from './routes';
import * as eimzoService from 'services/eimzo';
import { ScrollToTop } from './services/ScrollToTop';
import { BackTop } from 'antd';
import './assets/output.css';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { QueryParamProvider } from 'use-query-params';
import ErrorsReport from 'components/shared/Layout/ErrorsReport';
import UserProvider from 'pages/Attribute/UserProvider';
import VideoLessons from 'components/VideoLessons';
import Spinner from 'components/nic/Spinner';

const App = () => {
	useEffect(() => {
		eimzoService.startApi();
	}, []);

	return (
		<UserProvider>
			<QueryParamProvider adapter={ReactRouter5Adapter}>
				<div className='App'>
					<Layout>
						<Suspense
							fallback={
								<div className='flex items-center justify-center h-[300px] w-full bg-gray-100/10 select-none'>
									<Spinner className='text-gray-900 !h-6' />
								</div>
							}
						>
							<ErrorsReport />

							<VideoLessons />

							<ScrollToTop />
							<Switch>
								{routes.map(({ path, exact, component }, key) => (
									<Route {...{ key, exact, path, component }} />
								))}
							</Switch>
						</Suspense>
					</Layout>
					<BackTop className='!left-10 !bottom-12' />
				</div>
			</QueryParamProvider>
		</UserProvider>
	);
};

export default App;
