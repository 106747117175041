import React, { memo } from "react";
import classes from "./TreeItem.module.scss";
import { ReactComponent as PlusIcon } from "assets/svg/pluse.svg";
import { useTranslation } from "react-i18next";

type Props = {
	name: string;
	code: string;
	icon?: string;
	count?: any;
	isShowCount?: boolean;
	onAddFavorite?: () => void;
};
const TreeItem = ({ icon, code, name, count, isShowCount, onAddFavorite }: Props) => {
	const { t } = useTranslation();

	return (
		<div className={classes["tree-item"]}>
			<span className={classes.content}>
				{icon && <div style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: icon }} />}
				{code}&ensp;-&ensp;{name ? name : <b>{t("Корневой код")}</b>}
			</span>
			<div className={classes.extra}>
				{isShowCount ? (
					<>{<span className={classes.count}>{count}</span>}</>
				) : (
					<>{!!onAddFavorite && <PlusIcon style={{ width: "20px", height: "20px", marginLeft: 10, cursor:"pointer" }} onClick={() => onAddFavorite()} />}</>
				)}
			</div>
		</div>
	);
};

export default memo(TreeItem, memoPropsAreEqual);

function memoPropsAreEqual(prevProps: any, nextProps: any) {
	return (
		prevProps.name === nextProps.name &&
		prevProps.code === nextProps.code &&
		prevProps.icon === nextProps.icon &&
		prevProps.count === nextProps.count &&
		prevProps.isShowCount === nextProps.isShowCount &&
		prevProps.item === nextProps.item &&
		prevProps.onAddFavorite === nextProps.onAddFavorite
	);
}
