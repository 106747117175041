import { message } from 'antd';
import Button from 'components/Button';
import Captcha from 'components/Captcha';
import Input from 'components/shared/Input';
import get from 'lodash.get';
import IkpuSelect from 'pages/Cabinet/component/IkpuSelect';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { requestWithToken } from 'services/api';

interface IProps {
	onSuccess?: (...params: unknown[]) => unknown;
}

function ErrorReportIkpu({ onSuccess }: IProps) {
	const { t } = useTranslation();

	type TForm = {
		captchaGuid: string | null;
		captcha: string | null;

		fixText: string | null;
		mxikName: string | null;
		mxikCode: string | null;
		additional_info: string | null;
	};
	const [form, setForm] = useReducer(
		(prev: TForm, next: Partial<TForm>) => {
			return { ...prev, ...next };
		},
		{
			fixText: null,
			mxikCode: null,
			mxikName: null,
			additional_info: null,
			captchaGuid: null,
			captcha: null,
		} as TForm
	);

	const mutationFix = useMutation({
		mutationFn: () => {
			return requestWithToken({
				url: `/web/error/save`,
				method: 'POST',
				params: {
					captcha: form.captcha,
					guid: form.captchaGuid,
				},
				data: {
					mxik: form.mxikCode,
					errorTopic: form.mxikName,
					suggestTopic: `${form.fixText} - (${form.additional_info})`,
					status: 2,
				},
			});
		},
	});

	const onSelect = (value: string) => {
		const [mxikCode, mxikName] = value.split(/-(.*)/s);

		setForm({ mxikCode: mxikCode.trim() });
		setForm({ mxikName: mxikName.trim() });
	};

	const onSubmit = async () => {
		try {
			await mutationFix.mutateAsync();
			message.success(t('Спасибо за помощь'));
			setForm({
				fixText: null,
				mxikCode: null,
				mxikName: null,
				additional_info: null,
				captchaGuid: null,
				captcha: null,
			});
			onSuccess && onSuccess();
		} catch (error) {
			const errorMessage = get(error, 'response.data.error', null);

			if (errorMessage === `captcha parameter is missing`) {
				return message.error(t('Символы в рисунках не правильно		'));
			}

			message.error(t('Хатолик юз берди'));
		}
	};

	return (
		<>
			<div className='!w-full !space-y-8'>
				<IkpuSelect mxikValue={form.mxikCode} onSelect={onSelect} />
				<Input label={t('Каталогга мувофик товарлар/хизматлар номи')} value={String(form.mxikName || '')} />
				<Input
					name='fix'
					label={t('Предлагаемый текст')}
					value={form.fixText || ''}
					onChange={(e) => setForm({ fixText: e.currentTarget.value })}
				/>
				<Input
					name='additional_info'
					label={t('Дополнительная информация (телефон, ИНН и название организации)')}
					value={form.additional_info || ''}
					onChange={(e) => setForm({ additional_info: e.currentTarget.value })}
				/>
			</div>

			<div className='w-full'>
				<div className='flex items-center w-1/2 space-x-5'>
					<Captcha onCaptchaChange={({ captchaGuid }) => setForm({ captchaGuid })} />
					<div className='w-full relative'>
						<Input
							status={!form.captcha ? 'error' : ''}
							placeholder={t('Напишите символ из рисунка')}
							className='w-full mb-0 !bg-white'
							value={form.captcha || ''}
							onChange={(e) => setForm({ captcha: e.currentTarget.value })}
						/>
					</div>
				</div>
			</div>

			<div className='flex items-center justify-center space-x-10'>
				<Button
					type='link'
					size='large'
					className='!py-4 !rounded-full !text-sm no-underline text-blue-default hover:text-blue-default !border-none !bg-transparent !flex !items-center !justify-center !mb-0'
				>
					{t('Отменить')}
				</Button>

				<Button
					loading={mutationFix.isLoading}
					onClick={onSubmit}
					disabled={!form.captcha}
					type='primary'
					size='large'
					className='!py-4 cursor-pointer !px-10 !rounded-full !text-sm !border !border-gray-400 !flex !items-center !justify-center !mb-0'
				>
					{t('Отправить')}
				</Button>
			</div>
		</>
	);
}

export default ErrorReportIkpu;
