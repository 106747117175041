import cn from 'components/nic/cn';

function Spinner(props: any) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className={cn('!h-5 text-white', props.className)}>
			<g className='nic__spinner'>
				<path d='M11 1H13V6H11z' fill={`currentColor`} opacity='0.14'></path>
				<path d='M11 1H13V6H11z' opacity='0.29' transform='rotate(30 12 12)' fill='currentColor'></path>
				<path d='M11 1H13V6H11z' opacity='0.43' transform='rotate(60 12 12)' fill='currentColor'></path>
				<path d='M11 1H13V6H11z' opacity='0.57' transform='rotate(90 12 12)' fill='currentColor'></path>
				<path d='M11 1H13V6H11z' opacity='0.71' transform='rotate(120 12 12)' fill='currentColor'></path>
				<path d='M11 1H13V6H11z' opacity='0.86' transform='rotate(150 12 12)' fill='currentColor'></path>
				<path fill='currentColor' d='M11 1H13V6H11z' transform='rotate(180 12 12)'></path>
			</g>
		</svg>
	);
}

export default Spinner;
