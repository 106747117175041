import React, { FC, useState } from 'react';
import { message, Select as AntSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import classes from './DownloadFile.module.scss';
import { ReactComponent as DownloadImage } from 'assets/images/icons/dowload.svg';
import { Select } from 'components/shared';
import { Button } from 'components';
import { useCustomFileDownloadMutations, useFileDownloadMutation } from 'hooks/mutations';

type fileTypes = {
	id: number;
	value: string;
	label: string;
};
type Props = {
	fileTypes: fileTypes[];
};

const DownloadFile: FC<Props> = ({ fileTypes }) => {
	const { t } = useTranslation();
	const { Option } = AntSelect;
	const [isDownload, setIsDownload] = useState(false);

	const fileDownloadMutation = useFileDownloadMutation();
	const getCategoryExcelMutation = useCustomFileDownloadMutations(`/excel/get/category`);
	const [downloadValue, setDownloadValue] = useState('excel');
	const onDownload = (type: string) => {
		if (type === 'excel') {
			const mutationPromise = getCategoryExcelMutation.mutateAsync(0);
			const hide = message.loading(t('Файл юкланмоқда'), 0);
			setIsDownload(true);
			mutationPromise
				.then((response) => {
					const a = document.createElement('a');
					a.href = URL.createObjectURL(
						new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
						})
					);
					a.setAttribute('download', `classifier.xlsx`);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					hide();
					setIsDownload(false);
					message.success(t('Файл юкланди'));
				})
				.catch(() => {
					hide();
					setIsDownload(false);
					message.error(t('Файлни юклашда хатолик'));
				});
		} else {
			const mutationPromise = fileDownloadMutation.mutateAsync({ type });
			const hide = message.loading(t('Файл юкланмоқда'), 0);
			setIsDownload(true);
			mutationPromise
				.then((response) => {
					const a = document.createElement('a');
					switch (type) {
						case 'json': {
							a.href = URL.createObjectURL(
								new Blob([JSON.stringify(response.data, null, 2)], {
									type: `${type}/plain`,
								})
							);
							break;
						}
						// case "excel": {
						// 	a.href = URL.createObjectURL(
						// 		new Blob([response.data], {
						// 			type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
						// 		})
						// 	);
						// 	break;
						// }
						case 'xml': {
							const xmlText = '<sometag><someothertag></someothertag></sometag>';
							const link = (a.href = URL.createObjectURL(new Blob([xmlText], { type: 'text/plain' })));
							// @ts-ignore
							a.setAttribute('href', window.URL.createObjectURL(link));
							break;
						}
						default: {
							a.href = URL.createObjectURL(new Blob([response.data], { type: 'text/plain' }));
						}
					}
					a.setAttribute('download', `classifier.${type === 'excel' ? 'xls' : type}`);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					hide();
					setIsDownload(false);
					message.success(t('Файл юкланди'));
				})
				.catch(() => {
					hide();
					setIsDownload(false);
					message.error(t('Файлни юклашда хатолик'));
				});
		}
	};

	return (
		<div className={classes.download}>
			<DownloadImage />
			<span className={classes.label}>{t('Выгрузить в')}:</span>
			<Select
				placeholder={`${t('Выберите формат')}...`}
				className={'mr-10'}
				onSelect={(event: any) => setDownloadValue(event)}
			>
				{fileTypes.map((item) => (
					<Option value={item.value} key={item.id}>
						{item.label}
					</Option>
				))}
			</Select>
			<Button
				disabled={isDownload}
				loading={isDownload}
				size={'large'}
				tab
				subtle
				className={'pl-20 pr-20'}
				onClick={() => onDownload(downloadValue)}
			>
				{t('Скачать')}
			</Button>
		</div>
	);
};

export default DownloadFile;
