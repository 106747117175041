import React, { FC } from "react";
import { Menu as AntMenu } from "antd";
import cn from "classnames";

import classes from "./Menu.module.scss";

type Props = {
	className?: string;
	activeKeys?: string[];
	changeActiveKeys?: (e: React.Key[]) => void;
};

const Menu: FC<Props> = ({ className, activeKeys, changeActiveKeys, children }) => {
	return (
		<AntMenu
			onClick={(e) => changeActiveKeys && changeActiveKeys(e.keyPath)}
			selectedKeys={activeKeys && [activeKeys[0]]}
			openKeys={activeKeys && [activeKeys[1]]}
			defaultOpenKeys={activeKeys && [activeKeys[1]]}
			mode="inline"
			className={cn(className, classes.menu)}
			onOpenChange={(e) => changeActiveKeys && changeActiveKeys(e)}>
			{children}
		</AntMenu>
	);
};

export default Menu;
