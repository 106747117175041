import React, { FC } from "react";
import classes from "./ServiceBlockCard.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";


type Props = {
	title: string;
	className?: string;
	icon: string;
	path?: string;
	direction?: string;
	alert?: boolean;
	count?: number;
};

const ServiceBlockCard: FC<Props> = ({
										 title,
										 icon,
										 path,
										 direction = "horizontal",
										 alert,
										 count,
										 className
									 }) => {
	const classNames = cn(
		classes["service-block"],
		classes[`${direction}`]
	);

	return (
		<Link to="/" className={`${classNames} ${className}`}>
			<div className={classes.icon}>
				<img src={icon} alt="" />
				{alert && (<span className={classes.alert}></span>)}
				{count && <span className={classes.count}>{count}+</span>}
			</div>
			<div>
				<span>{title}</span>
			</div>
		</Link>
	);
};

export default ServiceBlockCard;