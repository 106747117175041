import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { request } from 'services/api';
import { quickSearchDTO } from 'types/utils';

type QueryData = quickSearchDTO;
type Props = {
	size?: number;
	page?: number;
	text?: string;
};
const useGetGroupQuery = ({ size, page, text }: Props = {}) => {
	const { t, i18n } = useTranslation();

	const query = useQuery(['/group', size, page, text], async () => {
		const response = await request.get<QueryData>(`/group`, { params: { size, page, text, lang: i18n.language } });

		return response;
	});

	return query;
};

export default useGetGroupQuery;
