import { memo } from 'react';
import { Button } from '../../../index';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
	activeTab: 'right' | 'wrong';
	setActiveTab: (value: 'right' | 'wrong') => void;
	rightLength: number;
	wrongLength: number;
	children?: React.ReactNode;
};

export const SearchLabel = memo(({ activeTab, setActiveTab, rightLength, wrongLength, children }: Props) => {
	const { t } = useTranslation();
	return (
		<Space size={10} className={'mb-5'}>
			<Button subtle type={activeTab === 'right' ? 'primary' : 'default'} onClick={() => setActiveTab('right')}>
				{t('Найдено совпадений')} - {rightLength}
			</Button>
			{children}
		</Space>
	);
});
