import { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { AutoComplete, Col, Radio, RadioChangeEvent, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import useDebounceHook from 'libs/useDebounceHook';
import classes from '../../Cabinet.module.scss';
import SearchCard from 'components/SearchCard';
import { Input } from 'components/shared';
import plusIcon from 'assets/svg/service-icon/plus.svg';
import { Button, Cards } from 'components';
import useSearchQuery from '../hooks/useSearchQuery';

const Option = AutoComplete.Option;

type Props = {
	onSelect: (value: any) => void;
	mxikValue?: any;
	isEdite?: boolean;
};

type TypeIkpuFilter = {
	text: string;
	size: 20;
	url: '/mxik/search/by-params' | '/mxik/search-subposition';
};

const IkpuSelect: FC<Props> = ({ onSelect, mxikValue, isEdite }) => {
	const [searchValue, setSearchValue] = useState(mxikValue);

	useEffect(() => {
		setSearchValue(mxikValue);
	}, [mxikValue]);

	const [searchSize, setSearchSize] = useState(20);
	const [radioValue, setRadioValue] = useState<'1' | '2' | '3' | '4'>('1');
	const { t } = useTranslation();
	const [searchActiveTab, setSearchActiveTab] = useState<'right' | 'wrong'>('right');
	// @ts-ignore
	const debouncedText = useDebounceHook(searchValue, 500);

	const { isLoading, data, symbolData, wrongLength, rightLength, onPopupScroll, isFetched } = useSearchQuery({
		text: debouncedText,
		radioValue,
		size: searchSize,
		activeTab: searchActiveTab,
		setSearchSize,
	});

	function onRadioChange(value: RadioChangeEvent) {
		setRadioValue(value.target.value);
		setSearchValue('');
		setSearchSize(20);
	}

	const OptionList = useMemo(() => {
		const renderData = radioValue === '2' && searchActiveTab === 'wrong' ? symbolData : data;

		return Array.isArray(renderData)
			? renderData?.map((item: any, index: number) => {
					const { mxikCode, mxikName, subPositionName } = item;

					const value = `${mxikCode} - ${String(mxikName || subPositionName)}`;

					return (
						<Option key={mxikCode} value={value}>
							{index === 0 ? (
								<>
									<Space size={10} className={'mb-10'}>
										<Button
											subtle
											type={searchActiveTab === 'right' ? 'primary' : 'default'}
											onClick={(event) => {
												setSearchActiveTab('right');
												event.stopPropagation();
											}}
										>
											{t('Найдено совпадений')} - {rightLength}
										</Button>
										{radioValue === '2' ? (
											<Button
												subtle
												type={searchActiveTab === 'wrong' ? 'primary' : 'default'}
												onClick={(event) => {
													setSearchActiveTab('wrong');
													event.stopPropagation();
												}}
											>
												{t('Возможно вы искали')} - {wrongLength}
											</Button>
										) : null}
									</Space>
								</>
							) : null}
							<SearchCard key={item.mxikCode} searchValue={searchValue} item={item} />
							{renderData.length === index + 1 && isLoading ? (
								<div className={classes.spinner}>
									<Spin spinning={isLoading} />
								</div>
							) : null}
						</Option>
					);
			  })
			: null;
	}, [searchActiveTab, data, radioValue, symbolData]);

	const onChange = (data: string) => {
		radioValue === '1' || radioValue === '2' ? setSearchValue(data) : setSearchValue(data?.replace(/[^\d]+/g, ''));
	};

	const notFoundContent = useMemo(() => {
		if (searchValue !== '') {
			if (isLoading && !isFetched) {
				return (
					<div className={classes.spinner}>
						<Spin />
					</div>
				);
			}
			return (
				OptionList?.length === 0 && (
					<>
						<Space size={10} className={'mb-10'}>
							<Button
								subtle
								type={searchActiveTab === 'right' ? 'primary' : 'default'}
								onClick={(event) => {
									setSearchActiveTab('right');
									event.stopPropagation();
								}}
							>
								{t('Найдено совпадений')} - {rightLength}
							</Button>
							{radioValue === '2' ? (
								<Button
									subtle
									type={searchActiveTab === 'wrong' ? 'primary' : 'default'}
									onClick={(event) => {
										setSearchActiveTab('wrong');
										event.stopPropagation();
									}}
								>
									{t('Возможно вы искали')} - {wrongLength}
								</Button>
							) : null}
						</Space>

						<Cards.InfoCard
							empty
							icon={plusIcon}
							text={t('Введите название товара правильно либо отправьте запрос на добавление')}
							path={'#'}
							direction={'vertical'}
							onClick={() => {}}
						/>
					</>
				)
			);
		}
	}, [OptionList, searchValue, searchValue, isFetched]);

	return (
		<div>
			<AutoComplete
				className='w-full'
				value={searchValue}
				onChange={onChange}
				filterOption={false}
				onPopupScroll={(event) => onPopupScroll(event)}
				onSelect={(e: any) => {
					setSearchValue(e);
					onSelect(e);
				}}
				notFoundContent={notFoundContent}
				listHeight={500}
			>
				{OptionList}

				<Input isSearch placeholder={`...`} label={t('ИКПУ')} searchType={'secondary'} loading={isLoading} />
			</AutoComplete>

			<Radio.Group onChange={onRadioChange} value={radioValue} className={classes.radio}>
				<Row justify='space-between' className='!w-full'>
					<Col lg={6} md={6} xs={24} className='flex items-center'>
						<Radio value={'1'}>{t('Расширенный поиск по тексту')}</Radio>
					</Col>
					<Col lg={6} md={6} xs={24} className='flex items-center'>
						<Radio value={'2'}>{t('Поиск по тексту')}</Radio>
					</Col>
					<Col lg={6} md={6} xs={24} className='flex items-center'>
						<Radio value={'3'}>{t('Поиск по ИКПУ')}</Radio>
					</Col>
					<Col lg={6} md={6} xs={24} className='flex items-center'>
						<Radio value={'4'}>{t('Поиск по штрих-коду')}</Radio>
					</Col>
				</Row>
			</Radio.Group>
		</div>
	);
};

export default IkpuSelect;
