import React, { FC, ReactNode } from 'react';
import { Table as AntTable, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';

import classes from './Table.module.scss';
import { DownloadFile } from 'components/shared';
import Filter from './components/Filter';
import { Key } from 'antd/es/table/interface';
import classNames from 'classnames';

type Props = TableProps<any> & {
	bordered?: boolean;
	download?: boolean;
	filter?: boolean;
	address?: boolean;
	paginationVisible?: boolean;
	paginationPosition?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
	itemSelection?: { itemCount: string; onSelect: (value: string) => void };
	searchValue?: string;
	searchValueChange?: (value: string) => void;
	deleteHandler?: (value: Key[]) => void;
	formatButtons?: { id: number; label: string; icon: ReactNode }[];
	withoutSearchFilter?: boolean;
};

const Table: FC<Props> = ({
	filter,
	paginationPosition,
	bordered = true,
	paginationVisible = true,
	download = true,
	address,
	itemSelection,
	searchValue,
	searchValueChange,
	deleteHandler,
	formatButtons,
	withoutSearchFilter,
	...props
}) => {
	const { t } = useTranslation();

	const fileTypes = [
		{ id: 1, value: 'pdf', label: 'PDF' },
		{ id: 2, value: 'doc', label: 'DOC' },
		{ id: 3, value: 'xls', label: 'XLS' },
	];

	return (
		<div className={classes.table}>
			{filter && (
				<Filter
					address={address}
					selectedRowKeys={props.rowSelection?.selectedRowKeys}
					itemSelection={itemSelection}
					searchValue={searchValue}
					searchValueChange={searchValueChange}
					deleteHandler={deleteHandler}
					formatButtons={formatButtons}
					withoutSearchFilter={withoutSearchFilter}
				/>
			)}
			<AntTable
				{...props}
				bordered={bordered}
				pagination={
					paginationVisible && {
						...props.pagination,
						position: [paginationPosition || 'bottomLeft'],
						showSizeChanger: false,
						itemRender: (current: number, type: string, originalElement: any) => {
							if (type === 'prev') {
								return <span>{t('Предыдущая')}</span>;
							}
							if (type === 'next') {
								return <span>{t('Следующая')}</span>;
							}
							return originalElement;
						},
					}
				}
			/>
			<div className={classNames(paginationVisible ? classes.download : '')}>
				{download && props.dataSource && <DownloadFile fileTypes={fileTypes} />}
			</div>
		</div>
	);
};

export default Table;
