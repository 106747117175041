import React, { useState } from 'react';
import classes from './Navbar.module.scss';
import { useTranslation } from 'react-i18next';
import Container from '../../../Container';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useGetProfileQuery } from 'hooks/queries';
import { useUser } from 'pages/Attribute/UserProvider';

const Navbar = () => {
	const { t } = useTranslation();
	const userQuery = useGetProfileQuery();

	const [active, setActive] = useState(0);
	const navLinks = [
		{
			path: '/',
			text: t('Главная'),
		},
		{
			path: '/news',
			text: t('Новое на портале'),
		},
		{
			path: !userQuery.data?.data ? '/sign-in' : '/activities',
			text: t('Отправленные заявки'),
		},
		{
			path: !userQuery.data?.data ? '/sign-in' : '/cabinet',
			text: t('Избранные ИКПУ'),
		},
	];

	const user = useUser();

	if (user) {
		navLinks.push({
			path: !userQuery.data?.data ? '/sign-in' : '/cabinet/market',
			text: t('Номеклатура для онлайн-ККТ'),
		});
	}

	return (
		<div className={classes.navbar}>
			<Container>
				<div className={classes.wrapper}>
					{navLinks.map((l, i) => {
						return (
							<Link
								onClick={() => setActive(i)}
								key={i}
								className={cn(classes.navMenu, { [classes.active]: i === active })}
								to={l.path}
							>
								{l.text}
							</Link>
						);
					})}
				</div>
			</Container>
		</div>
	);
};

export default Navbar;
