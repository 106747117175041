import React, { FC } from "react";
import cn from "classnames";
import classes from "./EmptyCard.module.scss";
import { Link } from "react-router-dom";
import { Typography } from "antd";

type Props = {
	icon?: string;
	direction?: string;
	text?: string;
	path: string;
	bg?: boolean;
	key?: number;
	smText?: string;
	width?: string;
	className?: string;
	onClick?: () => void;
	empty?: boolean;
	danger?: string;
	link?: string;
};

const EmptyCard: FC<Props> = ({
	icon,
	empty,
	link,
	danger,
	className,
	direction = "horizontal",
	onClick,
	text,
	path,
	bg,
	smText,
	...props
}) => {
	const classNames = cn(classes["empty-card"], classes[`${direction}`], bg && classes.bg, empty && classes.empty, className && className);

	return (
		<Link to={path} className={classNames} {...props} onClick={onClick}>
			{icon && (
				<div className={classes["empty-card__icon"]}>
					<img src={icon} alt={text} />
				</div>
			)}
			{text && (
				<h5>
					{text} {danger && <Typography.Text type="danger">{danger}</Typography.Text>}{" "}
					{link && <Typography.Text underline>{link}</Typography.Text>}
				</h5>
			)}
			{smText && <p>{smText}</p>}
		</Link>
	);
};

export default EmptyCard;
