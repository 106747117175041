import { useQuery } from "react-query";

import { request } from "services/api";
import { companyNameDTO } from "types/utils";

type QueryData = companyNameDTO[];
type Props = {
	gtin?: string;
};
const useGetCompanyProductsQuery = ({ gtin }: Props = {}) => {
	const query = useQuery(
		"/integration-mxik/company/get/basket-products",
		async () => {
			const response = await request.get<QueryData>(`/international/gs1/short-info-by-gtin/${gtin}`);

			return response;
		},
		{ enabled: !!gtin }
	);

	return query;
};

export default useGetCompanyProductsQuery;
