import React, { FC } from 'react';
import { Input, InputProps } from 'antd';
import './Input.scss';
import cn from 'classnames';
import { Tag } from '../index';

type AntInputProps = InputProps & {
  loading?: boolean;
  label?: string;
  isSearch?: boolean;
  disabled?: boolean;
  caption?: string;
  isSuccess?: boolean;
  successMsg?: string;
  inputType?: 'default' | 'primary' | undefined;
  size?: 'small' | 'middle' | 'large' | undefined;
  searchType?: 'primary' | 'secondary' | undefined;
};

const AntInput: FC<AntInputProps> = React.forwardRef(
  (
    { isSearch, label, caption, isSuccess, successMsg, size, inputType = 'default', searchType = 'primary', ...props },
    ref: any
  ) => {
    const { Search } = Input;

    const classNames = cn(
      'input',
      searchType === 'primary' && 'search-primary',
      searchType === 'secondary' && 'search-secondary',
      inputType === 'default' && 'default',
      isSuccess && 'is-success',
      size && size
    );

    return (
      <>
        <div className={classNames}>
          {label && <span className={'input-label'}>{label}</span>}
          {isSearch ? <Search {...props} size={size} type={'search'} ref={ref} /> : <Input {...props} size={size} />}
          {caption && <small>{caption}</small>}
          {successMsg && <Tag title={successMsg} type={'success'} />}
        </div>
      </>
    );
  }
);

export default AntInput;
