import React, { createContext, useContext } from 'react';
import { useGetProfileQuery } from '../../hooks/queries';
import get from 'lodash.get';

// @TODO user User this way globally
export const UserContext = createContext(null);

function UserProvider({ children }: { children: React.ReactNode }) {
	const userQuery = useGetProfileQuery();
	const user = get(userQuery, 'data.data', false);

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser() {
	const user = useContext(UserContext);

	return user as unknown;
}

export default UserProvider;
