import { useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import soonSvg from 'pages/Attribute/soon.svg';
import { useQuery } from 'react-query';
import { request } from 'services/api';
import get from 'lodash.get';

function ZoomImageWithAlternate({
	src,
	isService,
	idx,
	mxikCode,
}: {
	/**
	 * @deprecated
	 */
	src?: string;
	isService: boolean;
	idx: number;
	mxikCode: string;
}) {
	const queryMarkirovkaProductImages = useQuery({
		queryKey: ['queryMarkirovkaProductImages--modal', mxikCode],
		queryFn: async () => {
			const resImagesList = await request({
				url: `/integration-mxik/references/get/mxik/picture-names`,
				params: {
					mxik_code: mxikCode,
				},
			});

			const list = resImagesList.data as string[];

			return list.map((imgUrl: string) => {
				return {
					photo_url: `${process.env.REACT_APP_API_URL}/integration-mxik/references/get/file/`.concat(imgUrl),
				};
			});
		},
		retry: 0,
	});
	const ALL_IMAGES = get(queryMarkirovkaProductImages, 'data', []);
	const [error, setError] = useState<boolean>(false);

	const src2 = get(ALL_IMAGES, '0.photo_url', soonSvg);

	console.log(ALL_IMAGES);

	return (
		<>
			<img
				src={src || soonSvg}
				onError={(el) => {
					setError(true);
				}}
				className='!h-full w-full object-contain hidden'
			/>

			{error ? (
				<img src={isService ? '/service.png' : soonSvg} className='!h-full w-full object-contain' />
			) : (
				<div className='custom relative'>
					<div id={`magnify-${idx}`} className='absolute -left-[202%] -top-[50%]' />

					<ReactImageMagnify
						enlargedImageClassName='object-contain bg-gray-500/50'
						className='object-contain'
						enlargedImagePortalId={`magnify-${idx}`}
						enlargedImageContainerDimensions={{ width: '200%', height: '200%' }}
						{...{
							smallImage: {
								alt: 'Wristwatch by Ted Baker London',
								isFluidWidth: false,
								height: 270,
								width: 270,
								src: src2,
							},
							largeImage: {
								alt: '',
								src: src2,
								width: 800,
								height: 800,
							},
						}}
					/>
				</div>
			)}
		</>
	);
}

export default ZoomImageWithAlternate;
