import { useMutation } from 'react-query';
import { request } from 'services/api';

const useFileDownloadMutation = (url: string) => {
	return useMutation({
		mutationKey: url,
		mutationFn: (category_id?: any) => {
			return request({
				url,
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					category_id: category_id ? category_id : null,
				},
			});
		},
	});
};

export default useFileDownloadMutation;
